// ResponsiveDrawer.js
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../../config/constants";
import { useMenuCtx } from "../../context/MenuContext";
import MainDrawer from "../MainDrawer/MainDrawer";
import AppBarModule from "../../components/Header/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();

  const menuContext = useMenuCtx();

  return (
    <>
      < div className={classes.appBar}>
        <AppBarModule />
      </div>
      <div className={classes.root}>
        <CssBaseline />
        <MainDrawer
          open={menuContext.menuStatus}
          closeEvent={menuContext.handleDrawerToggle}
        />
        <main
          style={{
            width: "100%",
            overflow: "hidden",
            padding: "2rem",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
          className={classes.content}
        >
          {/* <Header /> */}
          {props.children}
        </main>
      </div>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
