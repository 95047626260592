import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { AuthProvider } from '../providers/AuthProvider';
import DefaultLoader from './DefaultLoader';
// import store from "../store/store";
import { GenerlProvider } from "../contexts/GeneralContext";

function AppProvider(props) {
    return (
        // <Provider>
            <GenerlProvider>
                {/* <ThemeProvider theme={theme}> */}
                    <SnackbarProvider maxSnack={3} anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                        <AuthProvider>
                            {/* <Router> */}
                                <Suspense fallback={<DefaultLoader />}>
                                    {props.children}
                                </Suspense>
                            {/* </Router> */}
                        </AuthProvider>
                    </SnackbarProvider>
                {/* </ThemeProvider> */}
             </GenerlProvider>
        // </Provider>
    )
}

export default AppProvider
