import React, { createContext, useContext } from "react";


const GeneralContext = createContext({refreshLibrary:()=>{}});

// Declare Auth provider
function GenerlProvider(props) {
  const refreshLibrary=()=>{
      alert('refresh Library')
  }

  return (
    <GeneralContext.Provider value={{refreshLibrary}}>
      {props.children}
    </GeneralContext.Provider>
  );
}

// custom hook
function useGeneralCtx() {
  const context = useContext(GeneralContext);

  if (context === undefined) {
    throw new Error(`useGeneralCtx must be used within a GenerlProvider`);
  }

  return context;
}

export { GenerlProvider, useGeneralCtx };

