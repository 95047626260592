import { Skeleton } from "@mui/material";
import React from "react";

const TextLoader = () => {
  return (
    <div style={{ padding: "1rem", width: "100%" }}>
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} width={"100%"} style={{marginBottom:'.3rem'}} />
    </div>
  );
};

export default TextLoader;
