import React from "react";

import "./WhiteCardWrapper.css";

const WhiteCardWrapper = ({ className, children }) => {
  return (
    <div className={`custom__WhiteCard ${className}`}>{children}</div>
  );
};

export default WhiteCardWrapper;
