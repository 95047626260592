import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import TableEmptyImg from "../assets/images/icons/tableEmptyState.svg";

const useStyles = makeStyles((theme2) => ({
  container: {
    // backgroundColor: "#FFFFFF",
    // height: "440px",
    // width: "100%",
  },
}));

function Datatables(props) {
  console.log("check props in Datatable", props);
  const classes = useStyles();
  const [sortBy, setSortBy] = useState({
    key: _.isUndefined(props.orderByKey) ? "name" : props.orderByKey,
    order: "ASC",
    sortByApplied: false,
  });

  const fetchMoreData = () => {
    if (props.hasMore) {
      props.getDataList(props.nextToken, sortBy);
    }
  };

  const applySorting = (i, header) => {
    props.tableHeader[i].orderBy =
      props.tableHeader[i].orderBy == "ASC" ? "DESC" : "ASC";

    // console.log('props.tableHeader[i].orderBy: ',props.tableHeader[i].orderBy)
    setSortBy({
      key: header.id,
      order: props.tableHeader[i].orderBy,
      sortByApplied: false,
    });

    props.getDataList(null, {
      key: header.id,
      order: props.tableHeader[i].orderBy,
      sortByApplied: true,
    });
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={props.data.length}
        next={fetchMoreData}
        hasMore={props.hasMore}
        height={"100%"}
        loader={
          <h4
            style={{
              textAlign: "center",
              position: "relative",
              bottom: "-3rem",
              margin: 0,
            }}
          >
            <CircularProgress size={25} />
          </h4>
        }
      >
        <TableContainer className={classes.container}>
          <Table border="0px" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {props.multiSelect == true && (
                  <TableCell
                    style={{
                      fontWeight: 700,
                    }}
                  ></TableCell>
                )}
                {props.tableHeader.map((header, i) => (
                  <TableCell
                    key={header.id}
                    align={header.align}
                    style={{
                      minWidth: header.minWidth,
                      // fontWeight: "bold",
                      border: "none",
                      // opacity: 0.5,
                     
                      background: "#D4D4D4"
                    }}
                    className={classes.tableHeadCell}
                    onClick={() =>
                      props.tableHeader[i].sortable == true &&
                      applySorting(i, header)
                    }
                  >
                    <Typography style={{  color:'#202224',
                      fontSize: "14px",
                      fontWeight:'500', }}>
                      {header.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "#FFFFFF" }}>
              {props.loading && props.isEmpty ? (
                <>
                  <TableRow>
                    {props.tableHeader.map((header, i) => (
                      <TableCell
                        key={header.id}
                        align={header.align}
                        style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      >
                        <Skeleton variant="rect" width={100} height={15} />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {props.tableHeader.map((header, i) => (
                      <TableCell
                        key={header.id}
                        align={header.align}
                        style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      >
                        <Skeleton variant="rect" width={100} height={15} />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {props.tableHeader.map((header, i) => (
                      <TableCell
                        key={header.id}
                        align={header.align}
                        style={{ minWidth: header.minWidth, fontWeight: 600 }}
                      >
                        <Skeleton variant="rect" width={100} height={15} />
                      </TableCell>
                    ))}
                  </TableRow>
                </>
              ) : (
                props.children
              )}
            </TableBody>
          </Table>
          {props.loading == false && props.isEmpty == true && (
            <Box style={{ backgroundColor: "#fff" }} display="flex">
              <Box flexGrow={1} textAlign="center" margin="10%">
                <img src={TableEmptyImg} height="160px" />
              </Box>
            </Box>
          )}
          {props.isNull == true && (
            <Box
              style={{
                margin: "5%",
                textAlign: "center",
                color: "#FFFFFF",
                fontSize: "14px",
              }}
            >
              No Suspension History
            </Box>
          )}
        </TableContainer>
      </InfiniteScroll>
    </div>
  );
}

export default Datatables;
