import { Menu, MenuItem, Select, IconButton, Tooltip } from "@mui/material";
import { TableCell, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, TableRow, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Datatables from "../../components/Datatables";
import useGeneralStyle from "../../assets/css/general";
import SearchIcon from "@mui/icons-material/Search";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useSnackbar } from "notistack";
import { apiClient } from "../../config/apiClient";
import moment from "moment";
import { Box } from "@mui/system";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { STATUS_COLOR } from "../../config/constants";
import _ from "lodash";
import SuspendUserModal from "./SuspendUserModal";
import ViewUserProfile from "./ViewUserProfile";
import Zoom from "@material-ui/core/Zoom";

const tableHeader = [
  { id: "CreatedAt", align: "left", label: "CREATED AT", minWidth: 120 },
  { id: "FirstName", align: "left", label: "FIRST NAME", minWidth: 120 },
  { id: "LastName", align: "left", label: "LAST NAME", minWidth: 120 },
  { id: "UserName", align: "left", label: "USERNAME", minWidth: 120 },
  { id: "PhoneNumber", align: "left", label: "PHONE", minWidth: 120 },
  { id: "Email", align: "left", label: "EMAIL", minWidth: 120 },
  {
    id: "DetailedProfile",
    align: "left",
    label: "DETAILED PROFILE",
    minWidth: 120,
  },
  { id: "Status", align: "left", label: "STATUS", minWidth: 120 },
];

// let diffTableHeader = [...tableHeader];
// diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 160,
    color: "white",
    borderBottom: "none",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  statusBox: {
    width: "150px",
    borderRadius: "5px",
    padding: "5px",
    textAlign: "center",
    fontWeight: "bold",
  },
  icon: {
    fill: "white",
  },
  root: {
    width: "100%",
    overflowX: "auto",
    // marginTop: '25px',
  },
  suspendedStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #fd6868",
    backgroundColor: "#ffc3c3",
    color: "#e64848",
    fontWeight: "bold",
  },
  suspendedStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #fd6868",
    backgroundColor: "#ffc3c3",
    color: "#e64848",
    fontWeight: "bold",
  },
  pendingStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #eab20a",
    backgroundColor: "#ffeeb5",
    color: "#eab20a",
    fontWeight: "bold",
  },
  registeredStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #6ac358",
    backgroundColor: "#c3f4ba",
    color: "#6ac358",
    fontWeight: "bold",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    // backgroundColor: theme2.palette.primary.light,
    borderBottom: "2px solid white ",
    borderRadius: "0px",
    color: "white",
    fontWeight: 600,
  },
  icon: {
    fill: "#FFFFFF",
  },
  carddelModal: {
    width: "500px",
    borderRadius: "12px",
    backgroundColor: "#1b1f3b",
    padding: "1rem",
    position: "relative",

    [theme2.breakpoints.down("sm")]: {
      width: "90vw !important",
    },

    "&:focus-visible": {
      outline: "none",
    },
  },
  profileData: {
    color: "white",
    paddingTop: "2px",
    fontSize: "16px",
    // fontWeight: 600,
  },
  profileHeading: {
    fontSize: "12px",
    // fontWeight: 600,
    color: "#B0B2B6",
    textAlign: "left",
  },

  parentofappointmentcontent: {
    [theme2.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  bottombox: {
    [theme2.breakpoints.down("xs")]: {
      marginLeft: "0 !important",
      display: "flex",
      justifyContent: "flex-start !important",
      alignItems: "flex-start !important",
    },
  },
  doublerarrow: {
    [theme2.breakpoints.down("xs")]: {
      transform: "rotate(90deg)",
      margin: "1rem !important",
    },
  },

  tabsContainer: {
    backgroundColor: "#fff",
    margin: "20px 20px 0px 20px",
    height: "44px",
    [theme2.breakpoints.down("md")]: {
      width: "95%",
      overflow: "scroll",
    },
    [theme2.breakpoints.down("sm")]: {
      width: "88%",
      overflow: "scroll",
    },
    "&::-webkit-scrollbar": {
      height: "0",
    },
  },
}));

const ProfessionalUsers = () => {
  const globalClasses = useGeneralStyle();
  const classes = useStyles();
  const getUsersListStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const notification = useSnackbar();
  const [search, setSearchKey] = useState("");
  const getUpdateAdminStatus = useCallbackStatus();

  const [userListData, setUserListData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("approved");
  const [rowId, setRowId] = useState(null);
  const [filter, setFilter] = useState("approved");
  const [userStatus, setUserStatus] = useState("completed");
  const [reason, setReason] = useState(false);
  const [adminComment, setAdminComment] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [showUser, setShowUser] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [tooltipIsOpen, setTooltipIsOpen] = useState(0);

  // ================== update user list api ====================

  const updateUserList = async (Id, action) => {
    console.log("id actionuser", Id);
    let apiBody = {};

    apiBody = {
      action_user_type: "user",
      action_user_id: Id,
      action: action,
      comment: adminComment,
    };

    console.log("updateuserlist apibody", apiBody);

    if (currentStatus !== "suspended") {
      delete apiBody.comment;
    }

    try {
      const res = await getUpdateAdminStatus.run(
        apiClient("POST", "admin", "userstatusactions", {
          body: { ...apiBody },
          shouldUseDefaultToken: false,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      const {
        content: { data },
      } = res;
      console.log("check updatedData", data);
      getUserList(null, true);
    } catch (err) {
      if (err && err.code === 403) {
        // logout();
        console.log("error", err);
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  // ================== get user list api ====================

  const getUserList = async (token, refresh = false) => {
    console.log("ref", refresh);
    let apiBody = {};

    apiBody = {
      search_string: search,
      status: filter,
      completion_status: userStatus,
      pagination_required: true,
      next_token: token,
      search_fields: ["first_name", "last_name", "email", "username"],
    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
      delete apiBody.search_fields;
    }

    console.log("userlist apibody", apiBody);

    try {
      const res = await getUsersListStatus.run(
        apiClient("POST", "user", "getuserbyfilter", {
          body: search || filter || userStatus ? apiBody : null,
          shouldUseDefaultToken: false,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      const {
        content: { data, has_more, next_token },
      } = res;

      if (refresh) setUserListData([...data]);
      else {
        setUserListData([...userListData, ...data]);
      }
      setHasMore(has_more);
      setTotalPages(next_token);
    } catch (err) {
      if (err && err.code === 403) {
        // logout();

        console.log("error", err);
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleSuspendAction = (rowId) => {
    setRowId(rowId);
    setReason(true);
  };

  useEffect(() => {
    getUserList(null, true);
  }, [currentStatus, filter, userStatus]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getUserList(null, true);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <>
      {reason ? (
        <SuspendUserModal
          setReason={setReason}
          setAdminComment={setAdminComment}
          updateUserList={() => updateUserList(rowId, "suspend")}
          adminComment={adminComment}
          setCurrentStatus={setCurrentStatus}
        />
      ) : null}

      {showUser ? (
        <ViewUserProfile
          setShowUser={setShowUser}
          userProfileData={userProfileData}
        />
      ) : null}

      <Grid container>
        <Grid
          container
          style={{ backgroundColor: "white", paddingLeft: "20px" }}
          className="ChangeRequestsContainer"
        >
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
            item
            xs={11}
            sm={4}
            md={4}
            lg={4}
          >
            <TextField
              onChange={(e) => setSearchKey(e.target.value)}
              variant="outlined"
              style={{ backgroundColor: "#F5F5F7", width: "400px" }}
              inputProps={{
                style: { padding: "12px" },
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    variant="filled"
                    fontSize="small"
                    style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                  />
                ),
                className: "inputFont",
              }}
              placeholder="Search user by name , username or email"
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "10px 0px",
            }}
            item
            xs={11}
            sm={5}
            md={5}
            lg={5}
          >
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              variant="outlined"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{
                marginLeft: "10px",
                paddingRight: "20px",
                backgroundColor: "#F5F5F7",
              }}
              className={globalClasses.selectInput}
            >
              <MenuItem key="approved" value="approved">
                Approved
              </MenuItem>
              <MenuItem key="suspended" value="suspended">
                Suspended
              </MenuItem>
            </Select>

            {/* -------- Drop down for complete incomplete --------- */}
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              variant="outlined"
              value={userStatus}
              onChange={(e) => setUserStatus(e.target.value)}
              style={{
                marginLeft: "10px",
                paddingRight: "20px",
                backgroundColor: "#F5F5F7",
              }}
              className={globalClasses.selectInput}
            >
              <MenuItem key="completed" value="completed">
                Completed
              </MenuItem>
              <MenuItem key="incompleted" value="incomplete">
                Incomplete
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <Paper className={classes.root}>
            <Datatables
              data={userListData}
              loading={getUsersListStatus.isPending}
              getDataList={(token) => getUserList(token, false)}
              hasMore={hasMore}
              nextToken={totalPages}
              tableHeader={tableHeader}
              orderByKey="name"
              isEmpty={userListData.length === 0}
            >
              {userListData.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      {row.created_at
                        ? moment.unix(row.created_at).format("Do MMM YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.first_name && row.first_name.length > 15
                        ? row.first_name.substring(0, 15) + "... "
                        : row.first_name || "-"}
                      {row.first_name && row.first_name.length > 15
                        ? [
                            <Tooltip
                              TransitionComponent={Zoom}
                              title={
                                <span style={{ fontSize: "12px" }}>
                                  {row.first_name}
                                </span>
                              }
                              aria-label="add"
                              open={row.first_name == tooltipIsOpen}
                              onOpen={() => setTooltipIsOpen(row.first_name)}
                              onClose={() => setTooltipIsOpen(0)}
                            >
                              <u
                                style={{
                                  color: "blueviolet",
                                  cursor: "pointer",
                                }}
                                onClick={() => setTooltipIsOpen(row.first_name)}
                              >
                                more
                              </u>
                            </Tooltip>,
                          ]
                        : null}
                    </TableCell>

                    <TableCell>
                      {row.last_name && row.last_name.length > 15
                        ? row.last_name.substring(0, 15) + "... "
                        : row.last_name || "-"}
                      {row.last_name && row.last_name.length > 15
                        ? [
                            <Tooltip
                              TransitionComponent={Zoom}
                              title={
                                <span style={{ fontSize: "12px" }}>
                                  {row.last_name}
                                </span>
                              }
                              aria-label="add"
                              open={row.last_name == tooltipIsOpen}
                              onOpen={() => setTooltipIsOpen(row.last_name)}
                              onClose={() => setTooltipIsOpen(0)}
                            >
                              <u
                                style={{
                                  color: "blueviolet",
                                  cursor: "pointer",
                                }}
                                onClick={() => setTooltipIsOpen(row.last_name)}
                              >
                                more
                              </u>
                            </Tooltip>,
                          ]
                        : null}
                    </TableCell>
                    <TableCell>
                      {row.username && row.username.length > 15
                        ? row.username.substring(0, 15) + "... "
                        : row.username || "-"}
                      {row.username && row.username.length > 15
                        ? [
                            <Tooltip
                              TransitionComponent={Zoom}
                              title={
                                <span style={{ fontSize: "12px" }}>
                                  {row.username}
                                </span>
                              }
                              aria-label="add"
                              open={row.id == tooltipIsOpen}
                              onOpen={() => setTooltipIsOpen(row.id)}
                              onClose={() => setTooltipIsOpen(0)}
                            >
                              <u
                                style={{
                                  color: "blueviolet",
                                  cursor: "pointer",
                                }}
                                onClick={() => setTooltipIsOpen(row.id)}
                              >
                                more
                              </u>
                            </Tooltip>,
                          ]
                        : null}
                    </TableCell>

                    <TableCell>
                      {row.phone_number ? row.phone_number : "-"}
                    </TableCell>
                    <TableCell>{row.email ? row.email : "-"}</TableCell>
                    <TableCell>
                      <u
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setUserProfileData(row);
                          setShowUser(true);
                        }}
                      >
                        View
                      </u>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box
                          style={{
                            backgroundColor: STATUS_COLOR[row.status],
                          }}
                          className={classes.statusBox}
                        >
                          <Typography style={{ color: "white" }}>
                            {_.upperCase(
                              row.status === "approved"
                                ? "Approved"
                                : row.status === "suspended" && "Suspended"
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton
                                  size="small"
                                  {...bindTrigger(popupState)}
                                >
                                  <ExpandMoreIcon
                                    style={{ color: "#141522" }}
                                  />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  {row?.status === "suspended" && (
                                    <Box>
                                      <MenuItem
                                        onClick={() => {
                                          setCurrentStatus("approved");
                                          popupState.setOpen(false);
                                          setReason(false);
                                          updateUserList(row.id, "approve");
                                        }}
                                      >
                                        Approve
                                      </MenuItem>
                                    </Box>
                                  )}
                                  {row?.status === "approved" && (
                                    <Box>
                                      <MenuItem
                                        onClick={() => {
                                          handleSuspendAction(row.id);
                                          popupState.setOpen(false);
                                        }}
                                      >
                                        Suspend
                                      </MenuItem>
                                    </Box>
                                  )}
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatables>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfessionalUsers;
