import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from "@mui/material/Collapse";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import _ from "lodash";
import * as React from 'react';
import { useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import medicalcircles from "../../assets/LeftSideBarIcons/medicalcircles.svg";
import { drawerWidth } from "../../config/constants";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from '@mui/icons-material/Menu';
import {
    Avatar,
} from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { menu } from "../Menu";

const useStyles = makeStyles((theme) => ({
    selectedItem: {
        borderLeft: "3px solid white !important",
    },
    menuItemIcon: {
        height: "FIT-CONTENT",
    },
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#252b49",
        // backgroundImage: 'linear-gradient(to bottom,#1c80ac,#95d6ff)',
        color: "white",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
}));


export default function TemporaryDrawer() {

    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [menuList, setMenuList] = useState(menu);
    const theme = useTheme();

    const location = useLocation();
    const path = location.pathname.split("/")[1];
    const auth = useAuth();
    const classes = useStyles();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const drawer = (
        <div style={{ padding: "1rem" }}>
            {/* <div className={classes.toolbar} /> */}

            <div>
                <Link to="/" style={{ display: "flex", flexDirection: "column" }}>
                    <Box style={{ margin: "0rem auto 1rem" }}>
                        <img src={medicalcircles} alt="MedicalCircles" />
                    </Box>
                </Link>
            </div>

            <List style={{ maxHeight: "58vh", overflowY: "auto" }}>
                {menuList.map((menuItem, index) => (
                    menuItem.hasAccess && (
                        <React.Fragment key={index}>
                            <ListItem
                                onClick={() => {
                                    if (menuItem.child.length === 0) {
                                        console.log("Clicked on ListItem", menuItem.url);

                                        if (menuItem.url === "/logout") {
                                            auth.logout();
                                        } else {
                                            navigate(menuItem.url);
                                        }
                                    }

                                    menuList[index].active = !menuItem.active;
                                    setMenuList([...menuList]);
                                }}
                                sx={{
                                    cursor: "pointer",
                                    backgroundColor:
                                        path === menuItem.rootpath ? "#F5F5F7" : "transparent",
                                    "&:hover": {
                                        backgroundColor: "#eee", // Add a different background color on hover
                                    },
                                }}
                                button
                                style={{ borderRadius: ".5rem", marginBottom: "0rem" }}
                            >
                                <ListItemIcon
                                    style={{
                                        // maxWidth: "1rem",
                                        margin: "auto",
                                    }}
                                >
                                    {path === menuItem.rootpath ? (
                                        <img
                                            style={{
                                                width: "23px",
                                                height: "auto",
                                                color: path === menuItem.rootpath ? "black" : "#8E92BC",
                                            }}
                                            src={menuItem?.activeIcon}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                width: "23px",
                                                height: "auto",
                                                color: path === menuItem.rootpath ? "black" : "#8E92BC",
                                            }}
                                            src={menuItem?.icon}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        style: {
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            // color: "#8E92BC",
                                            color: path === menuItem.rootpath ? "black" : "#8E92BC",
                                        },
                                    }}
                                    primary={menuItem.text}
                                />
                                {menuItem.child.length > 0 &&
                                    (menuItem.active ? (
                                        <ExpandLessIcon style={{ color: "gray" }} />
                                    ) : (
                                        <ExpandMoreIcon style={{ color: "gray" }} />
                                    ))}
                            </ListItem>
                            {!_.isEmpty(menuItem.child) && (
                                <Collapse in={menuItem.active} timeout="auto" unmountOnExit>
                                    {menuItem.child.map((child, cindex) => (
                                        <ListItem
                                            onClick={() => {
                                                navigate(child.url);
                                            }}
                                            activeClassName="active"
                                            // sx={{
                                            //   cursor: "pointer",
                                            //   backgroundColor:
                                            //     path === menuItem.rootpath ? "#F5F5F7" : "transparent",
                                            //   "&:hover": {
                                            //     backgroundColor: "#eee", // Add a different background color on hover
                                            //   },
                                            // }}
                                            exact
                                            // to={child.url}
                                            key={cindex}
                                            button
                                        >
                                            <ListItemIcon>
                                                {child.icon && (
                                                    <Avatar
                                                        variant="rounded"
                                                        style={{ width: "20px" }}
                                                        src={child.icon}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{
                                                    fontSize: "13px",
                                                    fontWeight: 400,
                                                }}
                                                primaryTypographyProps={{
                                                    style: {
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        // color: "#8E92BC",
                                                        color:
                                                            path === menuItem.rootpath ? "#8E92BC" : "#8E92BC",
                                                    },
                                                }}
                                                primary={child.text}
                                            />
                                        </ListItem>
                                    ))}
                                </Collapse>
                            )}
                        </React.Fragment>
                    )
                ))}
            </List>
            <div
                style={{
                    width: `calc(${drawerWidth} - 2rem)`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {/* <HelpCenter /> */}
            </div>
        </div>
    );



    return (
        <div>
            <Button onClick={toggleDrawer(true)}>
                <MenuIcon style={{ color: 'black' }} />
            </Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {drawer}
            </Drawer>
        </div>
    );
}
