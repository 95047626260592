import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function FormDialog({
  setReason,
  setAdminComment,
  admin_comment,
  getAllTickets,
  setCurrentStatus,
}) {
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setReason(false);
  };

  const handleSave = () => {
    if (!admin_comment) {
      setErrors("Please enter reason");
    } else {
      setCurrentStatus("rejected");
      setReason(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth onClose={handleClose}>
        <DialogTitle>Reject Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the reason for rejecting the ticket
          </DialogContentText>
          <TextField
            variant="outlined"
            autoFocus
            error={errors}
            id="name"
            fullWidth
            onChange={(e) => {
              setErrors(null);
              setAdminComment(e.target.value);
            }}
            multiline
            rows={5}
          />
          <FormHelperText style={{ color: "red" }}>{errors}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
