import logout from "../../assets/LeftSideBarIcons/logout.png";
import profile from "../../assets/LeftSideBarIcons/profile.png";
import activeProfile from "../../assets/LeftSideBarIcons/activeProfile.png";
import management from "../../assets/LeftSideBarIcons/management.png";
import activeManagement from "../../assets/LeftSideBarIcons/activeManagement.png";
import activeDashboard from "../../assets/LeftSideBarIcons/activeDashboard.png";
import dashboard from "../../assets/LeftSideBarIcons/dashboard.png";
import activeNetwork from "../../assets/LeftSideBarIcons/activeNetwork.png";
import network from "../../assets/LeftSideBarIcons/network.png";
import admin from "../../assets/LeftSideBarIcons/admin.png";
import analytics from "../../assets/LeftSideBarIcons/analytics.png";
import broadcast from "../../assets/LeftSideBarIcons/broadcast.png";
import billing from "../../assets/LeftSideBarIcons/billing.png";
import setting from "../../assets/LeftSideBarIcons/setting.png";


import speaker from "../../assets/LeftSideBarIcons/speaker.png";
import activeSpeaker from "../../assets/LeftSideBarIcons/activeSpeaker.png";
import ticket from "../../assets/LeftSideBarIcons/ticket.png";
import activeTicket from "../../assets/LeftSideBarIcons/activeTicket.png";
import company from "../../assets/LeftSideBarIcons/company.png";
import activeCompany from "../../assets/LeftSideBarIcons/activeCompany.png";
import profession from "../../assets/LeftSideBarIcons/profession.png";
import activeProfession from "../../assets/LeftSideBarIcons/activeProfession.png";
import category from "../../assets/LeftSideBarIcons/category.svg";
import _ from "lodash";


const hasAccess = (page) => {
    let activePages = [];
    activePages = JSON.parse(localStorage.getItem("activePages"));

    if (!_.isNull(activePages) && (activePages.includes(page) || page === "Yes")) {
        return true;
    } else return true;
};

const menu = [
    {
        text: "Overview", //Name of List Item
        hasAccess: hasAccess("Dashboard"),
        textCompare: "Dashboard",
        icon: dashboard, //Icon of List Item
        activeIcon: activeDashboard, //Icon of List Item
        rootpath: "overview", //RootPath used for selected view
        url: "/overview", //After Click where you want to redirect
        active: false,
        child: [],
    },
    {
        text: "Network",
        textCompare: "Network",
        hasAccess: hasAccess("User Management"),
        icon: network,
        activeIcon: activeNetwork,
        rootpath: "network",
        url: "/network",
        active: false,
        child: [
            {
                text: "Branch Management",
                textCompare: "Branch Management",
                icon: null,
                rootpath: "/network/branch",
                active: false,
                url: "/network/branch",
            },

        ],
    },
    {
        text: "Admins",
        textCompare: "Admins",
        hasAccess: hasAccess("User Requests"),
        icon: admin,
        activeIcon: activeTicket,
        rootpath: "admins",
        url: "/admins",
        active: false,
        child: [],
    },
    {
        text: "Analytics",
        textCompare: "Analytics",
        hasAccess: hasAccess("Role Access"),
        icon: analytics,
        activeIcon: activeTicket,
        rootpath: "analytics",
        url: "/analytics",
        active: false,
        child: [],
    },
    {
        text: "Broadcast",
        textCompare: "Broadcast",
        icon: broadcast,
        hasAccess: hasAccess("Support Tickets"),
        activeIcon: activeTicket,
        rootpath: "broadcast",
        url: "/broadcast",
        active: false,
        child: [],
    },
    // {
    //   text: "Groups",
    //   textCompare: "Groups",
    //   icon: ticket,
    //   activeIcon: activeTicket,
    //   rootpath: "groups",
    //   url: "/groups",
    //   active: false,
    //   child: [],
    // },
    {
        text: "Billing",
        textCompare: "Billing",
        hasAccess: hasAccess("Announcements"),
        icon: billing,
        activeIcon: activeSpeaker,
        rootpath: "billing",
        url: "/billing",
        active: false,
        child: [],
    },

];
const menu2 = [
    {
        text: "Settings",
        textCompare: "Settings",
        hasAccess: hasAccess("User Requests"),
        icon: setting,
        activeIcon: activeTicket,
        rootpath: "setting",
        url: "/setting",
        active: false,
        child: [],
    },
    {
        text: "Logout", //Name of List Item
        hasAccess: hasAccess("User Requests"),
        textCompare: "Logout",
        icon: logout, //Icon of List Item
        activeIcon: logout, //Icon of List Item
        rootpath: "logout", //RootPath used for selected view
        url: "/logout", //After Click where you want to redirect
        active: false,
        child: [],
    }
];
export { menu, menu2 };
