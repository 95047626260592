import React, { createContext, useContext } from "react";
import { apiClient } from "../config/apiClient";
var CryptoJS = require("crypto-js");

const AuthContext = createContext({
  getUserId: () => {},
  logout: () => {},
  setUser: () => {},
  getAll: () => {},
});

function AuthProvider(props) {
  const getUserId = () => {
    return window.localStorage.getItem("userId");
  };

  const getUser = () => {
    let user = window.localStorage.getItem("user");
    return JSON.parse(user);
  };

  const getAll = () => {
    const ciphertext = window.localStorage.getItem("Basic");
    if (ciphertext) {
      var bytes = CryptoJS.AES.decrypt(
        ciphertext,
        process.env.REACT_APP_DEFAULT_SECRET_KEY
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } else return;
  };

  const setUser = (user) => {
    const { email, last_name, first_name, profile_url, id, active_pages } =
      user;
    let activePages = active_pages.map((page) => page.page);
    window.localStorage.setItem("activePages", JSON.stringify(activePages));
    window.localStorage.setItem("userId", user.id);
    window.localStorage.setItem("user_token", user.secret.secret);
    window.localStorage.setItem("email", user.email);
    window.localStorage.setItem(
      "user",
      JSON.stringify({ email, last_name, first_name, profile_url, id })
    );
  };

  const getUserToken = () => {
    let userToken = window.localStorage.getItem("user_token");
    return userToken;
  };

  const logout = async () => {
    console.log("logout api call.");
    try {
      await apiClient("POST", "common", "logoutuser", {
        body: {
          // user_id: getUserId(),
          // user_type: "admin",
          // device_id: DEVICE_ID
        },
        shouldUseDefaultToken: false,
        enableLogging: true,
      });

      window.localStorage.clear();
      window.location.pathname = "/login";
    } catch (error) {
      console.log("in catch block");
      window.localStorage.clear();
      window.location.pathname = "/login";
    }
  };
  //   const logout = () => {
  //     window.localStorage.clear()
  //     window.location.reload('/login')
  //   }

  return (
    <AuthContext.Provider
      value={{ getUserId, getUserToken, setUser, logout, getUser, getAll }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };

