// api domain
export const apiDomainAdmin = process.env.REACT_APP_BASE_URL_ADMIN;
export const apiDomainCommon = process.env.REACT_APP_BASE_URL_COMMON;
export const apiDomainSetting = process.env.REACT_APP_BASE_URL_SETTING;
export const apiDomainUser = process.env.REACT_APP_BASE_URL_USER;

// api basepath
let apiBasePathObj;

/** DEV */
const devAPIBasePaths = {
  common: "common",
  stripe: "stripe",
  admin: "admin",
  multimedia: "multimedia",
  appointment: "appointment",
  admin_setting: "admin_setting",
  settings: "settings",
  feed: "feed",
  cms: "cms",
  content: "content",
  client: "client",
  expert: "expert",
  patient: "patient",
  comment: "comment",
  feedback: "feedback",
  payment: "payment",
  community: "community",
  templateandguidemgmt: "templateandguidemgmt",
  organisation: "organisation",
  user:"user"
};

/** Test */
const testAPIBasePaths = { ...devAPIBasePaths };

/** Prod */
const prodAPIBasePaths = { ...devAPIBasePaths };

// load the appropriate base paths
switch (process.env.REACT_APP_ENVIRONMENT) {
  case "dev":
    apiBasePathObj = devAPIBasePaths;
    break;
  case "test":
    apiBasePathObj = testAPIBasePaths;
    break;
  case "prod":
    apiBasePathObj = prodAPIBasePaths;
    break;
  default:
    apiBasePathObj = devAPIBasePaths;
}

// export it
export const apiBasePath = apiBasePathObj;
