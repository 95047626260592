import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import { CircularProgress, makeStyles } from "@material-ui/core";

function Modal({
  title,
  status,
  children,
  maxWidth,
  closeBtnText,
  saveBtnText,
  anotherSaveBtnText,
  saveHandler,
  anotherSaveHandler,
  buttonAlign,
  closeHandler,
  height,
  width,
  closeBtnColor,
  headerComponent,
  loading,
  anotherLoading,
}) {
  const useStyles = makeStyles((theme) => ({
    modalPaper: {
      width: _.isUndefined(width) ? "100%" : width,
      height: _.isUndefined(height) ? "440px" : height,
      // borderRadius: '16px',
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: classes.modalPaper }}
        style={{ borderRadius: "50px", width: "100%" }}
        open={status}
        maxWidth={_.isUndefined(maxWidth) ? "sm" : maxWidth}
        fullWidth
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            color: "white",
            backgroundColor: "#141522",
            padding: "9px 20px",
          }}
        >
          {_.isUndefined(title) ? headerComponent() : title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {(!_.isUndefined(saveBtnText) || !_.isUndefined(closeBtnText)) && (
          <DialogActions
            style={{
              marginRight: "20px",
              marginBottom: "20px",
              justifyContent: buttonAlign ? buttonAlign : "flax-end",
            }}
          >
            {!_.isUndefined(closeBtnText) && (
              <Button
                variant="text"
                onClick={closeHandler}
                style={{
                  color: closeBtnColor ? closeBtnColor : "white",
                  width: "20%",
                }}
              >
                {_.isUndefined(closeBtnText) ? "Close" : closeBtnText}
              </Button>
            )}
            {!_.isUndefined(anotherSaveBtnText) && (
              <Button
                variant="contained"
                onClick={anotherSaveHandler}
                color="primary"
                autoFocus
                // style={{ width: "20%", marginLeft: "20px" }}
                style={{marginLeft: "20px" }}
                disabled={anotherLoading}
              >
                {anotherLoading ? (
                  <CircularProgress size={23} />
                ) : (
                  <>{_.isUndefined(anotherSaveBtnText) ? "Save" : anotherSaveBtnText}</>
                )}
              </Button>
            )}
            {!_.isUndefined(saveBtnText) && (
              <Button
                variant="contained"
                onClick={saveHandler}
                color="primary"
                autoFocus
                style={{ marginLeft: "20px", backgroundColor: "#141522" }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={23} />
                ) : (
                  <>{_.isUndefined(saveBtnText) ? "Save" : saveBtnText}</>
                )}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default Modal;
