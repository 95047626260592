import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";

import OuterLayout from "../../layouts/login/OuterLayout";
import TextField from "../../MuiComponent/TextField";
import useCancelRequest from "../../hooks/useCancelToken";

import { useAuth } from "../../providers/AuthProvider";
import { apiClient } from "../../config/apiClient";
import { forgotPasswordValidation } from "../../validations";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { validateEmail } from "../../hooks/Validation";

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontSize: "24px",
    fontWeight: 500,
    color: "white",
    padding: "10% 10% 0 10%",
  },
  subHeading: {
    fontSize: "22px",
    fontWeight: 600,
    color: "white",
    padding: "7% 0 7% 10%",
  },
  emailField: {
    padding: "0 10% 0% 10%",
    height: "60px",
  },
  passwordField: {
    margin: "0% 10% 1% 10%",
    height: "40px",
    width: "80%",
  },
  forgotPassword: {
    margin: "2% 10% 0 0",
    textDecoration: "none",
    color: "white",
    // color: theme.palette.custom.heading,
    fontSize: "14px",
    fontWeight: 400,
  },
  loginButton: {
    marginTop: "8%",
    marginBottom: "8%",
    fontWeight: "600",
    borderRadius: "10px",
    width: "80%",
    height: "50%",
  },
  icon: {
    backgroundColor: "#141522",
  },

  RecaptchaDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "1rem auto 0",
  },
}));

function ForgotPassword(props) {
  let navigate = useNavigate();
  let auth = useAuth();
  const apiSource = useCancelRequest();
  const forgotApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const classes = useStyles();

  return (
    <div className="forgotpassword-content-shift">
      <OuterLayout Welcometext={true} is_back_button={false} on_back="/login">
        <Box className={classes.mainHeading}>
          Thank You!
          <Box
            style={{
              fontWeight: 300,
              fontSize: "13px",
              opacity: 0.8,
              marginTop: "10px",
            }}
          >
            You have successfully submitted request to delete <br /> your
            account
          </Box>
        </Box>

        <Box style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            // disabled={forgotApiStatus.isPending}
            // onClick={submitForm}
            onClick={() => {
              navigate("/home");
            }}
            variant="contained"
            className={classes.loginButton}
            color="primary"
          >
            {forgotApiStatus.isPending ? (
              <CircularProgress size={23} color="secondary" />
            ) : (
              "Back to Home"
            )}
          </Button>
        </Box>
      </OuterLayout>
    </div>
  );
}

export default ForgotPassword;
