import { useFormik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header"


import {
  Box,
  Button,
  Chip,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableRow
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useGeneralStyle from "../../assets/css/general";


import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import Datatables from "../../components/Datatables";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
import {
  managePolicyValidate,
  titleValidate,
} from "../../validations/login-validate";

const tableHeader = [
  { id: "date", align: "left", label: "LAST UPDATED", minWidth: 200 },
  {
    id: "role_name",
    label: "ROLE NAME",
    minWidth: 170,
    align: "left",
  },
  {
    id: "number",
    label: "NO. OF USERS",
    minWidth: 140,
    align: "left",
  },
  {
    id: "policies",
    label: "NO. OF POLICIES",
    minWidth: 160,
    align: "left",
  },
  {
    id: "policies",
    label: "ATTACHED POLICIES",
    minWidth: 320,
    align: "left",
  },
  {
    id: "manage",
    label: "MANAGE",
    minWidth: 130,
    align: "left",
  },
];

const useStyles = makeStyles((theme) => ({
  inputOutlined: {
    padding: "10.5px 14px",
  },
  icon: {
    fill: "#FFFFFF",
  },
  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingRight: "20px",
    backgroundColor: "#252b49",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  popupIndicator: {
    color: "white",
  },
  clearIndicator: {
    color: "white",
  },
  languageChip: {
    marginLeft: "10px",
    marginBottom: "5px",
    marginTop: "5px",
  },
  inputRoot: {
    backgroundColor: "inherit",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  tag: {
    color: "white",
  },
  tabsContainer: {
    backgroundColor: "#252b49",
    margin: "20px 20px 0px 20px",
    height: "44px",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      overflow: "scroll",
    },
    [theme.breakpoints.down("sm")]: {
      width: "88%",
      overflow: "scroll",
    },
    "&::-webkit-scrollbar": {
      height: "0",
    },
  },
}));

function RoleManagement() {
  const classes = useStyles();
  const globalClasses = useGeneralStyle();
  const roleAccessApiStatus = useCallbackStatus();
  const policyListApiStatus = useCallbackStatus();
  const manageApiStatus = useCallbackStatus();
  const managePolicyApiStatus = useCallbackStatus();
  const auth = useAuth();
  const apiSource = useCancelRequest();
  const notification = useSnackbar();

  const [search, setSearchKey] = useState("");
  const [roleAccessList, setRoleAccessList] = useState([]);
  const [userObj, setUserObject] = useState({});
  const [modal, setModal] = useState(false);
  const [policiesModal, setPoliciesModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [policiesList, setPoliciesList] = useState([]);
  const [originalPolicyList, setOriginalPolicyList] = useState([]);

  const addFormik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: titleValidate,

    onSubmit: () => {
      manageStatus("add", addFormik.values.title);

    },
  });

  const manageFormik = useFormik({
    initialValues: {
      // role: "",
      policy: [],
    },
    validationSchema: managePolicyValidate,

    onSubmit: () => {
      // manageStatus("add", values.title);
      managePolicy();
    },
  });

  let getPoliciesList = async () => {
    let apiBody = {
      order_by: {
        key: "page",
        order: "DESC",
      },
    };

    await policyListApiStatus.run(
      apiClient("POST", "admin", "getportalpage", {
        body: apiBody,
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          const {
            content: { data },
          } = result;
          setPoliciesList(data.data);
          setOriginalPolicyList(data.data);
        })
        .catch((error) => {
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  };

  let getRoleAccessList = async (token, refresh) => {
    let apiBody = {
      // user_id: localStorage.getItem("userId"),
      // user_type: "admin",
      search_string: search,
      next_token: token,
      pagination_required: true,
      "order_by":
      {
        "key": "created_at",
        "order": "DESC"
      }

    };

    if (apiBody.search_string === "") {
      delete apiBody.search_string;
    }

    console.log("aapi")
    console.log(apiBody)
    await roleAccessApiStatus.run(
      apiClient("POST", "admin", "getpageaccessroles", {
        body: apiBody,
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          const {
            content: { data, has_more, next_token },
          } = result;

          setHasMore(data.has_more);
          setTotalPages(data.next_token);

          console.log("-----Table-----", data);

          if (refresh) {
            setRoleAccessList([...data.data]);
          } else {
            setRoleAccessList([...roleAccessList, ...data.data]);
          }
        })
        .catch((error) => {
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  };

  const manageStatus = async (action, role = "", roleID = "") => {
    let apiBody = {};
    if (action === "add") {
      apiBody = {
        action: action,
        role: role,
        // user_id: localStorage.getItem("userId"),
        // user_type: "admin",
      };
    }

    if (action === "delete") {
      apiBody = {
        action: action,
        role_id: roleID,
        // user_id: localStorage.getItem("userId"),
        // user_type: "admin",
      };
    }

    await manageApiStatus.run(
      apiClient("POST", "admin", "manageroles", {
        body: apiBody,
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          getRoleAccessList(null, true);
          if (action === "add") {
            setModal(false);
          }
          if (action === "update") {
            setPoliciesModal(false);
          }
          if (action === "delete") {
            setOpenModal(false);
          }
          addFormik.resetForm();

        })
        .catch((err) => {
          if (err && err.code === 403) {
            auth.logout();
          } else {
            notification.enqueueSnackbar(err.message, {
              variant: "err",
              autoHideDuration: 2000,
            });
          }
        })
    );
  };

  const managePolicy = async () => {
    let newArr = manageFormik.values.policy.map((item) => item.id);
    let apiBody = {
      // user_id: localStorage.getItem("userId"),
      // user_type: "admin",
      role_id: userObj.id,
      page_ids: newArr,
    };

    await managePolicyApiStatus.run(
      apiClient("POST", "admin", "managepageaccessroles", {
        body: apiBody,
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          getRoleAccessList(null, true);
          manageFormik.resetForm();
          setPoliciesModal(false);
        })
        .catch((err) => {
          if (err && err.code === 403) {
            auth.logout();
          } else {
            notification.enqueueSnackbar(err.message, {
              variant: "err",
              autoHideDuration: 2000,
            });
          }
        })
    );
  };

  useEffect(() => {
    getRoleAccessList(null, true);
    getPoliciesList();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getRoleAccessList(null, true);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleStateChange = (_, newValue) => {
    manageFormik.setFieldValue("policy", newValue);
  };

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Add New Role
          </Box>
          <Button
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
              addFormik.setValues(null);
              addFormik.resetForm();
            }}
          >
            <CloseIcon size="large" style={{ color: "white" }} />
          </Button>
        </Box>
      </>
    );
  };

  const PoliciesModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Manage Policies
          </Box>
          <Button
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setPoliciesModal(!policiesModal);
              manageFormik.setValues(null);
              manageFormik.resetForm();
            }}
          >
            <CloseIcon size="large" style={{ color: "white" }} />
          </Button>
        </Box>
      </>
    );
  };

  const ConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            You can’t delete this role
          </Box>
        </Box>
      </>
    );
  };

  const NewConfirmationModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
            Confirm delete
          </Box>
        </Box>
      </>
    );
  };

  const SmartBtn = ({ data }) => {
    let [showLess, setShowLess] = useState(true);

    if (data.length < 4) {
      return (
        <>
          {data.map((item, index) => {
            return (
              <Button
                key={index}
                variant="outlined"
                disableRipple
                style={{
                  borderRadius: "6px",
                  border: "1px solid #E27851",
                  backgroundColor: "#251E40",
                  height: "30px",
                  margin: "5px",
                  color: "#DFE2E7",
                  cursor: "default",
                  textTransform: "none",
                }}
              >
                {item.page}
              </Button>
            );
          })}
        </>
      );
    }

    return (
      <>
        {showLess ? (
          <>
            {data.map((item, index) => {
              if (index < 3) {
                return (
                  <Button
                    key={index}
                    variant="outlined"
                    disableRipple
                    style={{
                      borderRadius: "6px",
                      border: "1px solid #E27851",
                      backgroundColor: "#251E40",
                      height: "30px",
                      margin: "5px",
                      color: "#DFE2E7",
                      cursor: "default",
                      textTransform: "none",
                    }}
                  >
                    {item.page}
                  </Button>
                );
              }
            })}
            <Button
              variant="outlined"
              disableRipple
              onClick={() => {
                setShowLess(!showLess);
              }}
              style={{
                borderRadius: "6px",
                border: "1px solid #E27851",
                backgroundColor: "#251E40",
                height: "30px",
                margin: "5px",
                color: "#DFE2E7",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              <KeyboardArrowDownIcon /> more
            </Button>
          </>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  disableRipple
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #E27851",
                    backgroundColor: "#251E40",
                    height: "30px",
                    margin: "5px",
                    color: "#DFE2E7",
                    cursor: "default",
                    textTransform: "none",
                  }}
                >
                  {item.page}
                </Button>
              );
            })}
            <Button
              variant="outlined"
              disableRipple
              onClick={() => {
                setShowLess(!showLess);
              }}
              style={{
                borderRadius: "6px",
                border: "1px solid #E27851",
                backgroundColor: "#251E40",
                height: "30px",
                margin: "5px",
                color: "#DFE2E7",
                cursor: "pointer",
                textTransform: "none",
              }}
            >
              <KeyboardArrowUpIcon /> less
            </Button>
          </>
        )}
      </>
    );
  };

  const onDeletePolicy = (item) => {
    manageFormik.setFieldValue(
      "policy",
      manageFormik.values.policy.filter((policy) => policy.id != item.id)
    );
    // let indexCat = manageFormik.values.policy.indexOf(item);
    // manageFormik.values.policy.splice(indexCat, 1);

    // let policyExist = manageFormik.values.policy.map((item) => item.id);
    // setPoliciesList(
    //   originalPolicyList.filter((item) => {
    //     return !policyExist.includes(item.id);
    //   })
    // );

    // manageFormik.setFieldValue("policy", [...manageFormik.values.policy]);
  };

  return (
    <>
      <Header />
      <Grid container>
        {/* ---------- Search and Add Button --------- */}
        <Grid
          container
          style={{
            backgroundColor: "white",
            paddingLeft: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
            item
            xs={11}
            sm={5}
            md={5}
            lg={5}
          >
            <TextField
              onChange={(e) => setSearchKey(e.target.value)}
              variant="outlined"
              style={{ backgroundColor: "#F5F5F7", width: "400px" }}
              inputProps={{
                style: { padding: "12px" },
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    variant="filled"
                    fontSize="small"
                    style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                  />
                ),
              }}
              placeholder="Search by Role Name"
            />
          </Grid>

          <Grid
            className={classes.button}
            item
            xs={11}
            sm={4}
            md={4}
            lg={4}
            style={{ backgroundColor: "white" }}
          >
            <Button
              onClick={() => {
                setModal(!modal);
              }}
              color="primary"
              variant="contained"
              style={{ color: "white", backgroundColor: "#141522" }}
            >
              ADD NEW ROLE
            </Button>
          </Grid>
        </Grid>

        {/* ---------- Table View --------- */}

        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <Paper className={classes.root}>
            <Datatables
              data={roleAccessList}
              loading={roleAccessApiStatus.isPending}
              getDataList={(token) => getRoleAccessList(token, false)}
              hasMore={hasMore}
              nextToken={totalPages}
              tableHeader={tableHeader}
              orderByKey="name"
              isEmpty={roleAccessList.length === 0}
            >
              {roleAccessList.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      {moment
                        .unix(row.last_updated)
                        .format("Do MMM YYYY, h:mm a")}
                    </TableCell>

                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.no_of_users}</TableCell>
                    <TableCell>{row.no_of_modules}</TableCell>
                    <TableCell>
                      {row.module_names.length === 0 ? (
                        <Button
                          variant="outlined"
                          disableRipple
                          onClick={() => {
                            setUserObject(row);
                            manageFormik.setFieldValue(
                              "policy",
                              row.module_names
                            );
                            setPoliciesModal(!policiesModal);
                            manageFormik.setValues(null);
                            manageFormik.resetForm();
                          }}
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #E27851",
                            backgroundColor: "#251E40",
                            height: "30px",
                            margin: "5px",
                            color: "#DFE2E7",
                            cursor: "default",
                            textTransform: "none",
                          }}
                        >
                          + Add
                        </Button>
                      ) : (
                        <>
                          <SmartBtn data={row.module_names} />
                          {/* {JSON.stringify(row.module_names)} */}
                        </>
                      )}
                    </TableCell>
                    {/* <TableCell>
                        {row?.module_names?.length === 0 ? (
                          <Button
                            variant="outlined"
                            disableRipple
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #E27851",
                              backgroundColor: "#251E40",
                              height: "30px",
                              margin: "5px",
                              color: "#DFE2E7",
                              cursor: "default",
                            }}
                          >
                            + Add
                          </Button>
                        ) : row.module_names.length < 4 ? (
                          row.module_names?.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                variant="outlined"
                                disableRipple
                                style={{
                                  borderRadius: "6px",
                                  border: "1px solid #E27851",
                                  backgroundColor: "#251E40",
                                  height: "30px",
                                  margin: "5px",
                                  color: "#DFE2E7",
                                  cursor: "default",
                                }}
                              >
                                {item.page}
                              </Button>
                            );
                          })
                        ) : (
                          <>
                            {row.module_names?.map((item, index) => {
                              if (index < 3) {
                                return (
                                  <Button
                                    key={index}
                                    variant="outlined"
                                    disableRipple
                                    style={{
                                      borderRadius: "6px",
                                      border: "1px solid #E27851",
                                      backgroundColor: "#251E40",
                                      height: "30px",
                                      margin: "5px",
                                      color: "#DFE2E7",
                                      cursor: "default",
                                    }}
                                  >
                                    {item.page}
                                  </Button>
                                );
                              }
                              // let clicked = false;

                              // if (clicked === false) {
                              //   row.module_names.map((item, index) => {
                              //     if (index < 3) {
                              //       return (
                              //         <Button
                              //           variant="outlined"
                              //           disableRipple
                              //           style={{
                              //             borderRadius: "6px",
                              //             border: "1px solid #E27851",
                              //             backgroundColor: "#251E40",
                              //             height: "30px",
                              //             margin: "5px",
                              //             color: "#DFE2E7",
                              //             cursor: "default",
                              //           }}
                              //         >
                              //           {item.page}
                              //         </Button>
                              //       );
                              //     }
                              //   });
                              //   <Button
                              //     variant="outlined"
                              //     disableRipple
                              //     onClick={() => {
                              //       clicked = !clicked;
                              //     }}
                              //     style={{
                              //       borderRadius: "6px",
                              //       border: "1px solid #E27851",
                              //       backgroundColor: "#251E40",
                              //       height: "30px",
                              //       margin: "5px",
                              //       color: "#DFE2E7",
                              //       cursor: "default",
                              //     }}
                              //   >
                              //     <KeyboardArrowDownIcon /> more
                              //   </Button>;
                              // } else {
                              //   row.module_names.map((item) => {
                              //     return (
                              //       <Button
                              //         variant="outlined"
                              //         disableRipple
                              //         style={{
                              //           borderRadius: "6px",
                              //           border: "1px solid #E27851",
                              //           backgroundColor: "#251E40",
                              //           height: "30px",
                              //           margin: "5px",
                              //           color: "#DFE2E7",
                              //           cursor: "default",
                              //         }}
                              //       >
                              //         {item.page}
                              //       </Button>
                              //     );
                              //   });
                              //   <Button
                              //     variant="outlined"
                              //     disableRipple
                              //     onClick={() => {
                              //       clicked = !clicked;
                              //     }}
                              //     style={{
                              //       borderRadius: "6px",
                              //       border: "1px solid #E27851",
                              //       backgroundColor: "#251E40",
                              //       height: "30px",
                              //       margin: "5px",
                              //       color: "#DFE2E7",
                              //       cursor: "default",
                              //     }}
                              //   >
                              //     <KeyboardArrowUpIcon /> less
                              //   </Button>;
                              // }
                            })}
                            <Button
                              variant="outlined"
                              disableRipple
                              // onClick={() => {
                              //   clicked = !clicked;
                              // }}
                              style={{
                                borderRadius: "6px",
                                border: "1px solid #E27851",
                                backgroundColor: "#251E40",
                                height: "30px",
                                margin: "5px",
                                color: "#DFE2E7",
                                cursor: "default",
                              }}
                            >
                              <KeyboardArrowDownIcon /> more
                            </Button>
                          </>
                        )}
                      </TableCell> */}

                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState) => (
                              <React.Fragment>
                                <IconButton
                                  size="small"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreHorizIcon style={{ color: "#141522" }} />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                  <MenuItem
                                    onClick={() => {
                                      setUserObject(row);
                                      manageFormik.setFieldValue(
                                        "policy",
                                        row.module_names
                                      );

                                      setPoliciesModal(true);
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Manage Policies
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      if (row.no_of_users !== 0) {
                                        setConfirmation(true);
                                      } else {
                                        setUserObject(row);
                                        setOpenModal(true);
                                        // manageStatus("delete", "", row.id);
                                      }
                                      popupState.setOpen(false);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatables>
          </Paper>
        </Grid>
      </Grid>

      {/* ---------- Manage Policies Modal --------- */}

      <Modal
        height="400px"
        width="100%"
        headerComponent={PoliciesModalHeader}
        saveBtnText="Save"
        loading={managePolicyApiStatus.isPending}
        closeBtnText="Discard"
        closeHandler={() => {
          setPoliciesModal(!policiesModal);
          manageFormik.resetForm();
        }}
        saveHandler={() => {
          manageFormik.submitForm();
        }}
        status={policiesModal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Role Title
                </InputLabel>
                <TextField
                  id="role"
                  name="role"
                  // error={manageFormik.touched.role && manageFormik.errors.role}
                  // helperText={
                  //   manageFormik.touched.role && manageFormik.errors.role
                  // }
                  // defaultValue={userObj.name}
                  value={userObj.name}
                  // onChange={manageFormik.handleChange}
                  // onBlur={manageFormik.handleBlur}
                  // value={manageFormik.values.role}
                  // FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  // placeholder="Write here"
                  variant="outlined"
                />

                <InputLabel
                  className={globalClasses.inputLabel}
                  style={{ marginTop: "20px" }}
                >
                  Policies
                </InputLabel>
                <div>
                  <Autocomplete
                    multiple
                    // getOptionDisabled={(options) =>
                    //   values.policy.length == 3 ? true : false
                    // }
                    noOptionsText={
                      <Box style={{ color: "#252b49" }}>{
                        manageFormik.values.policy.length > 0 ? "All policies selected" : "No results found"
                      }</Box>
                    }
                    defaultValue={manageFormik.values.policy}
                    value={manageFormik.values.policy}
                    id="policy"
                    name="policy"
                    // id="tags-outlined"
                    // limitTags={3}`
                    options={policiesList.filter(
                      (elem) =>
                        !manageFormik.values.policy.find(
                          ({ id }) => elem.id === id
                        )
                    )}
                    onChange={handleStateChange}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.page}
                    filterSelectedOptions
                    classes={{
                      popupIndicator: classes.popupIndicator,
                      clearIndicator: classes.clearIndicator,
                      tag: classes.tag,
                      input: classes.inputRoot,
                    }}
                    ChipProps={{
                      size: "small",
                      className: classes.languageChip,
                    }}
                    renderTags={() => { }}
                    size="small"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Box style={{ color: "#252b49" }}>{option.page}</Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        helperText={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        variant="outlined"
                        className="hoverhide"
                        style={{
                          backgroundColor: "white",
                          color: "#303652",
                          height: "40px",
                          // background: "#212543",
                        }}
                        placeholder="Select Policy"
                      />
                    )}
                  />

                  <Box>
                    {manageFormik.values.policy.map((item, index) => (
                      <Chip
                        style={{
                          marginRight: "8px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "6px",
                          border: "1px solid #E27851",
                          backgroundColor: "#252b49",
                          color: "white",
                        }}
                        // style={{
                        //   height: "30px",
                        //   margin: "5px",
                        //   cursor: "default",
                        // }}
                        variant="outlined"
                        onDelete={() => {
                          onDeletePolicy(item);
                        }}
                        label={item.page}
                        deleteIcon={
                          <CloseIcon
                            style={{ color: "white", height: "11px" }}
                          />
                        }
                      />
                    ))}
                  </Box>
                </div>
                {/* <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  value={values.add_faq_category}
                  className={globalClasses.selectInput}
                  variant="outlined"
                  id="add_faq_category"
                  name="add_faq_category"
                  error={touched.add_faq_category && errors.add_faq_category}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                >
                  <MenuItem value="none" disabled>
                    Select Policy
                  </MenuItem>
                  {policiesList &&
                    modal &&
                    policiesList.map((elem) => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))} 
                </Select> */}
                {/* {touched.add_faq_category && errors.add_faq_category && (
                  <FormHelperText error variant="outlined">
                    {touched.add_faq_category && errors.add_faq_category}
                  </FormHelperText>
                )} */}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {/* ---------- Add Role Modal --------- */}

      <Modal
        headerComponent={ModalHeader}
        status={modal}
        loading={manageApiStatus.isPending}
        height="254px"
        width="550px"
        closeBtnText="Cancel"
        saveBtnText="Add"
        saveHandler={addFormik.submitForm}
        closeHandler={() => {
          setModal(!modal);
          addFormik.setValues(null);
          addFormik.resetForm();
        }}
      >
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <>
                  <InputLabel className={globalClasses.inputLabel}>
                    Title(Role Name):
                  </InputLabel>
                  <TextField
                    id="title"
                    name="title"
                    error={addFormik.touched.title && addFormik.errors.title}
                    onChange={addFormik.handleChange}
                    onBlur={addFormik.handleBlur}
                    value={addFormik.values.title}
                    variant="outlined"
                    placeholder="Write here"
                    className={globalClasses.selectInput}
                  />
                  {addFormik.touched.title && addFormik.errors.title && (
                    <FormHelperText error variant="outlined">
                      {addFormik.touched.title && addFormik.errors.title}
                    </FormHelperText>
                  )}
                </>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {/* ---------- Delete Role Modal  (Warning) --------- */}

      <Modal
        height="200px"
        width="100%"
        buttonAlign="center"
        headerComponent={ConfirmationModalHeader}
        saveBtnText="okay"
        status={confirmation}
        closeHandler={() => {
          setConfirmation(!confirmation);
        }}
        saveHandler={() => {
          setConfirmation(!confirmation);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
          }}
        >
          Yo can’t delete this role because it has active accounts,
          <br /> please shift to the other roles
        </Box>
      </Modal>

      {/* Popup to delete the role  */}
      <Modal
        height="200px"
        width="100%"
        headerComponent={NewConfirmationModalHeader}
        status={openModal}
        loading={manageApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Yes"
        // saveHandler={addFormik.submitForm}
        closeHandler={() => {
          setOpenModal(!openModal);
        }}
        saveHandler={() => {
          manageStatus("delete", "", userObj.id);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            color: "#252b49",
          }}
        >
          Are you sure you want to delete this role ?
        </Box>
      </Modal>
    </>
  );
}

export default RoleManagement;
