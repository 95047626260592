import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import Header from "../../layouts/Header";
import { useSnackbar } from "notistack";
import TextField from "../../MuiComponent/TextField";
import TextLoader from "../../components/UI/TextLoader/TextLoader";
import { apiClient } from "../../config/apiClient";
import { useAuth } from "../../contexts/authContext";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";

const useStyles = makeStyles((theme) => ({
  activeFilter: {
    borderBottom: "2px solid white ",
    borderRadius: "0px",
    color: "white",
    fontWeight: 600,
  },
  root: {
    margin: "20px",
  },
  error: {
    color: "red",
    marginLeft: "8px",
    fontSize: "12px",
    padding: "10px",
  },
}));

const PrivacyPolicy = () => {
  const { logout } = useAuth();
  const classes = useStyles();
  const getCmsApiStatus = useCallbackStatus();
  const manageCmsApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const notification = useSnackbar();

  // +---------------------------- Get and Set methods definitions ----------------------------+

  const tab = "client";

  const [isEditMode, setEditMode] = useState(false);
  const [getCmsData, setCmsData] = useState("");
  const [clientError, setClientError] = useState("");

  // +------------------------------ api call for GET_CMS_PAGES -------------------------------+
  const getCmsPagesApi = async (apiBody) => {
    try {
      const res = await getCmsApiStatus.run(
        apiClient("POST", "settings", "unauth/getcmspages", {
          body: {
            user_id: localStorage.getItem("userId"),
            user_type: "admin",
          },
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );
      const {
        content: { data },
      } = res;
      console.log("cms Data", data);
      setCmsData(data.privacy_policy_professional.content);
    } catch (err) {
      if (err && err.code === 403) {
        // logout();

        console.log("error via get cms pages", err);
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "err",
          autoHideDuration: 2000,
        });
      }
    }
  };

  const manageCmsPagesApi = async () => {
    try {
      const res = await manageCmsApiStatus.run(
        apiClient("POST", "settings", "managecmspages", {
          body: {
            cms_page_data: [
              {
                cms_page_name: `privacy_policy_professional`,
                cms_page_content: getCmsData,
              },
            ],
          },
          shouldUseDefaultToken: false,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );
      const {
        content: { data },
      } = res;
      console.log("CMS pages managed successfully", data);
    } catch (err) {
      if (err && err.code === 403) {
        // logout();
        console.log("error in manage", err);
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  const handleSubmit = () => {
    if (!getCmsData) {
      console.log("no cms");
      setClientError("Privacy Policy Required.");
      return;
    }

    if (!getCmsData.replace(/\s/g, "").length) {
      console.log("wrong cms");
      setClientError("Enter Valid Privacy Policy.");
      return;
    }
    setClientError("");

    manageCmsPagesApi();
  };

  useEffect(() => {
    getCmsPagesApi();
  }, []);

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid style={{ borderBottom: "1px solid #c9c9c9" }} item xs={12}>
            <Box display="flex">
              <Box
                style={{ margin: "20px", color: "#141522", fontSize: "20px" }}
              >
                Privacy Policy
              </Box>
              <Box
                flexGrow={1}
                alignSelf="center"
                textAlign="end"
                style={{ margin: "10px 10px", marginLeft: "10px" }}
              >
                {isEditMode ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="text"
                      className={classes.editButton}
                      style={{ marginRight: "8px", color: "black" }}
                      onClick={() => {
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.editButton}
                      style={{ color: "white", backgroundColor: "#141522" }}
                      onClick={() => {
                        handleSubmit();
                        setEditMode(false);
                      }}
                    >
                      {getCmsApiStatus.isPending ? (
                        <CircularProgress
                          size={23}
                          style={{ color: "#BAC8FF" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.editButton}
                    onClick={() => setEditMode(true)}
                    style={{ color: "white", backgroundColor: "#141522" }}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          {isEditMode && tab === "client" ? (
            <>
              <Grid item xs={12} style={{ padding: "7px" }}>
                <TextField
                  onChange={(e) => setCmsData(e.target.value)}
                  value={getCmsData}
                  disabled={!isEditMode}
                  multiline
                  fullWidth
                  inputProps={{
                    style: {
                      padding: "12px",
                      backgroundColor: "#F5F5F7",
                      color: "#141522",
                    },
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              {clientError && (
                <Typography className={classes.error}>{clientError}</Typography>
              )}
            </>
          ) : !isEditMode && tab === "client" ? (
            <>
              {getCmsApiStatus.isPending ? (
                <TextLoader />
              ) : (
                <Grid item xs={12} style={{ padding: "7px" }}>
                  <TextField
                    onChange={(e) => setCmsData(e.target.value)}
                    value={getCmsData}
                    disabled={!isEditMode}
                    multiline
                    fullWidth
                    inputProps={{
                      style: {
                        padding: "12px",
                        backgroundColor: "#fff",
                        color: "#141522",
                      },
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
};

export default PrivacyPolicy;
