const { makeStyles } = require("@material-ui/core");

const useGeneralStyle = makeStyles((theme) => ({
  inputLabel: {
    color: "#1e1e1e",
    fontWeight: 600,
    fontSize: "14px",
    padding: "10px 0px",
    marginTop: "10px",
    fontFamily: "Helvetica Neue , sans-serif",
  },
  filterLabel: {
    color: "black",
    fontWeight: 600,
    fontSize: "13px",
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
    color: "blueviolet",
  },
  selectInput: {
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
  datatablePaper: {
    width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
    padding: "10px",
  },
}));

export const styles = {
  reportTable: {
    border: "2px solid #707070",
    borderCollapse: "collapse",
    width: "100%",
    color: "#6c6c6c",
  },
  reportTr: { borderBottom: "2px solid #707070" },
  reportTh: { borderRight: "2px solid #707070" },
  headingH1: {
    color: "black",
    fontSize: "22px",
  },
};

export default useGeneralStyle;
