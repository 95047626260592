import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Email, Phone } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import { apiClient } from "../../config/apiClient";
// import useCallbackStatus from "../../hooks/useCallbackStatus";
// import useCancelRequest from "../../hooks/useCancelToken";
// import Header from "../../layouts/Header";
import TextField from "../../MuiComponent/TextField";
// import { useSnackbar } from "notistack";
// import { useAuth } from "../../contexts/authContext";
import { useFormik } from "formik";
// import { contactUsSchema } from "../../validations/contactUsValidation";
// import { getRoles } from "@testing-library/dom";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "16px",
    fontWeight: 600,
  },
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  root: {
    // border: '1px solid #ccc',
    margin: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    padding: "1rem",
  },
  action: {
    color: theme.palette.primary.main_secondary,
    fontSize: "20px",
    textTransform: "none",
  },
  content: {
    padding: theme.spacing(4),
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 3),
  },
  editButton: {
    textTransform: "none",
  },
  icon: {
    color: "#C3C3C3",
  },
  error: {
    color: "red",
    marginLeft: "8px",
    fontSize: "12px",
    padding: "10px",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  // const apiSource = useCancelRequest();
  // const getCmsApiStatus = useCallbackStatus();
  // const updateCmsPages = useCallbackStatus();
  // const notification = useSnackbar();
  // const { logout } = useAuth();
  // const auth = useAuth();

  // +---------------------------- Get and Set methods definitions ----------------------------+
  const [tab, setTab] = useState("about");
  const [isEditMode, setEditMode] = useState(false);
  const [cmsData, setCmsData] = useState(
    "About medical circles details. isduhcysdc osdhcoysdcy s dcoysd8 osdco8syd8c s,dkcjsd osdiuc8dusc sducosduc wecowiucoisuc8yd coucsdyc9ysd oycsdyc9yfc sfcys9f8yw89yf9w 9wyf9w8d"
  );
  const [getEmail, setEmail] = useState("");
  const [getContactNo, setContactNo] = useState("");
  const [getNote, setNote] = useState("");
  const [aboutError, setAboutError] = useState("");

  // +------------------------------ api call for GET_CMS_PAGES -------------------------------+
  // const getCmsPages = async (apiBody) => {
  //   try {
  //     const res = await getCmsApiStatus.run(
  //       apiClient("POST", "setting", "getcmspages", {
  //         body: {
  //           user_id: localStorage.getItem("userId"),
  //           user_type: "admin",
  //         },
  //         shouldUseDefaultToken: true,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //     );
  //     const {
  //       content: { data },
  //     } = res;
  //     data && data.about_us && data.about_us.content
  //       ? setCmsData(data.about_us.content)
  //       : setCmsData({});
  //     data && data.contact_us && data.contact_us.content
  //       ? setNote(data.contact_us.content)
  //       : setNote({});
  //     data && data.contact_us_email && data.contact_us_email.content
  //       ? setEmail(data.contact_us_email.content)
  //       : setEmail({});
  //     data && data.contact_us_phone && data.contact_us_phone.content
  //       ? setContactNo(data.contact_us_phone.content)
  //       : setContactNo({});
  //   } catch (err) {
  //     if (err && err.code === 403) {
  //       logout();
  //     } else {
  //       notification.enqueueSnackbar(err.message, {
  //         variant: "err",
  //         autoHideDuration: 2000,
  //       });
  //     }
  //   }
  // };

  // +----------------------------- api call for UPDATE_CMS_PAGES -----------------------------+
  // const updateCmsData = async (apiBody) => {
  //   try {
  //     const res = await updateCmsPages.run(
  //       apiClient("POST", "setting", "managecmspages", {
  //         body: { ...apiBody },
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         isContact: true,
  //         enableLogging: false,
  //       })
  //     );
  //     const { code } = res;

  //     if (code === 200) {
  //       getCmsPages({});
  //       setEditMode(false);
  //     }

  //     notification.enqueueSnackbar(res.message, {
  //       variant: "success",
  //       autoHideDuration: 2000,
  //     });
  //   } catch (err) {
  //     if (err && err.code === 403) {
  //       logout();
  //     } else {
  //       notification.enqueueSnackbar(err.message, {
  //         variant: "err",
  //         autoHideDuration: 2000,
  //       });
  //     }
  //   }
  // };

  // const handleSubmitForAboutUs = () => {
  //   if (!cmsData) {
  //     console.log("no cms");
  //     setAboutError("About Us Required");
  //     return;
  //   }
  //   if (!cmsData.replace(/\s/g, "").length) {
  //     console.log("wrong cms");
  //     setAboutError("Enter Valid Info");
  //     return;
  //   }
  //   setAboutError("");
  //   const apiBody = {
  //     user_id: localStorage.getItem("userId"),
  //     user_type: "admin",
  //     cms_page_data: [
  //       {
  //         cms_page_name: "about_us",
  //         cms_page_content: cmsData,
  //       },
  //     ],
  //   };

  //   console.log("APIBODY Update ABOUT:", apiBody);
  //   updateCmsData(apiBody);
  // };

  // const getRoles = async () => {
  //   let apiBody = { admin_id: localStorage.getItem("userId") };
  //   console.log("APIBODY: GETROLES: ", JSON.stringify(apiBody));
  //   await updateCmsPages.run(
  //     apiClient("POST", "setting", "getroles", {
  //       body: apiBody,
  //       shouldUseDefaultToken: false,
  //       cancelToken: apiSource.token,
  //       isContact: true,
  //       enableLogging: false,
  //     }).then((result) => {
  //       console.log("REsult: ", result);
  //     })
  //   );
  // };

  useEffect(() => {
    // getRoles();
    // getCmsPages({});
  }, []);

  useEffect(() => {
    setCmsData(cmsData);
  }, [cmsData]);

  // +-------------------- data validation and error handling using formik --------------------+

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: getEmail || "",
      contactNo: getContactNo || "",
      note: getNote || "",
    },
    // validationSchema: contactUsSchema,
    onSubmit: () => {
      const apiBody = {
        user_id: localStorage.getItem("userId"),
        user_type: "admin",
        cms_page_data: [
          {
            cms_page_name: "contact_us",
            cms_page_content: getNote,
          },
          {
            cms_page_name: "contact_us_email",
            cms_page_content: getEmail,
          },
          {
            cms_page_name: "contact_us_phone",
            cms_page_content: getContactNo,
          },
        ],
      };
      console.log("update payload:", apiBody);
      // updateCmsData(apiBody);
    },
  });

  const { touched, errors, handleSubmit, handleChange, values, handleBlur } =
    formik;

  return (
    <>
      {/* <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Company info
        </Typography>
      </Header> */}

      <Paper elevation={0} className={classes.root}>
        <Grid container>
          <Grid style={{ borderBottom: "1px solid #c9c9c9" }} item xs={12}>
            <Box display="flex">
              <Box
                style={{ margin: "20px", fontSize: "20px", color: "#141522" }}
              >
                About Medical Circles
                {/* <Button
                    disableElevation
                    onClick={() => setTab('about')}
                    className={clsx({ [classes.activeFilter]: tab === 'about' })}
                    variant="contained"
                  >
                    About us
                  </Button> */}
              </Box>
              {/* <Box style={{ margin: '10px 10px', marginLeft: '10px' }}>
                    <Button
                      disableElevation
                      onClick={() => {
                        setTab('contact')
                        setEditMode(false)
                      }}
                      className={clsx({
                        [classes.activeFilter]: tab === 'contact',
                      })}
                      variant="contained"
                    >
                      Contact us
                    </Button>
                  </Box> */}
              <Box
                flexGrow={1}
                alignSelf="center"
                textAlign="end"
                style={{ margin: "10px 10px", marginLeft: "10px" }}
              >
                {isEditMode ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="text"
                      style={{ marginRight: "8px", color: "white" }}
                      onClick={() => {
                        // setEditMode(false);
                        // setCmsData(cmsData);
                        // getCmsPages({});
                        // setAboutError("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      // disabled={updateCmsPages.isPending}
                      // onClick={handleSubmit}
                      // onClick={() => {
                      //   tab === "about"
                      //     ? handleSubmitForAboutUs()
                      //     : handleSubmit();
                      // }}
                    >
                      {/* {updateCmsPages.isPending ? (
                        <CircularProgress
                          size={20}
                          className={classes.loader}
                        />
                      ) : ( */}
                      "Submit"
                      {/* )} */}
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(true)}
                  >
                    EDIT
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          {isEditMode && tab === "about" ? (
            <>
              <Grid item xs={12} style={{ padding: "7px" }}>
                <TextField
                  onChange={(e) => setCmsData(e.target.value)}
                  value={cmsData}
                  disabled={!isEditMode}
                  placeholder="Write about here ..."
                  multiline
                  fullWidth
                  inputProps={{
                    style: {
                      padding: "12px",
                      backgroundColor: "#F5F5F7",
                      color: "#141522",
                    },
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              {aboutError && (
                <Typography className={classes.error}>{aboutError}</Typography>
              )}
            </>
          ) : !isEditMode && tab === "about" ? (
            <>
              <Grid item xs={12} style={{ padding: "7px" }}>
                {
                  // getCmsApiStatus.isPending
                  false ? (
                    <Box style={{ textAlign: "center" }}>
                      {" "}
                      <CircularProgress size={20} />{" "}
                    </Box>
                  ) : (
                    <TextField
                      onChange={(e) => setCmsData(cmsData)}
                      value={cmsData}
                      disabled={!isEditMode}
                      placeholder="Write about here ..."
                      multiline
                      fullWidth
                      inputProps={{
                        style: {
                          padding: "12px",
                          backgroundColor: "#F5F5F7",
                          color: "#141522",
                        },
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  )
                }
              </Grid>
            </>
          ) : isEditMode && tab === "contact" ? (
            <>
              <Grid item container xs={6} style={{ padding: "20px" }}>
                <Grid className={classes.heading} item xs={12}>
                  Note
                </Grid>
                <TextField
                  id="admin-note"
                  name="note"
                  value={getNote}
                  onChange={(e) => {
                    setNote(e.target.value);
                    handleChange(e);
                  }}
                  disabled={!isEditMode}
                  onBlur={handleBlur}
                  multiline
                  error={errors.note && touched.note && errors.note}
                  type="text"
                  margin="dense"
                  fullWidth
                  rows={5}
                  placeholder="Enter you notes"
                  InputProps={{ disableUnderline: true }}
                />
                {touched.note && errors.note && (
                  <FormHelperText error variant="outlined">
                    {touched.note && errors.note}
                  </FormHelperText>
                )}
              </Grid>
              <Grid
                container
                item
                xs={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <Box
                  className={classes.heading}
                  style={{ marginBottom: "10px" }}
                >
                  Contact and Email
                </Box>
                <Box>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Email
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        id="admin-email"
                        name="email"
                        value={getEmail}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          handleChange(e.target.value);
                        }}
                        onBlur={handleBlur}
                        disabled={!isEditMode}
                        error={errors.email && touched.email && errors.email}
                        type="text"
                        margin="dense"
                        fullWidth
                        placeholder="Enter you email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error variant="outlined">
                          {touched.email && errors.email}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Contact No
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        className={classes.number}
                        id="admin-contact"
                        name="contactNo"
                        type="number"
                        value={values.contactNo}
                        onChange={(e) => {
                          setContactNo(e.target.value);
                          handleChange(e.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          onKeyDown: (event) => {
                            if (event.key == "e") event.preventDefault();
                          },
                        }}
                        variant="outlined"
                        margin="dense"
                        error={
                          errors.contactNo &&
                          touched.contactNo &&
                          errors.contactNo
                        }
                        fullWidth
                        disabled={!isEditMode}
                        placeholder="Enter contact number"
                        classes={{ root: classes.inputField }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.contactNo && errors.contactNo && (
                        <FormHelperText error variant="outlined">
                          {touched.contactNo && errors.contactNo}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : !isEditMode && tab === "contact" ? (
            <>
              <Grid item container xs={6} style={{ padding: "20px" }}>
                <Grid className={classes.heading} item xs={12}>
                  Note
                </Grid>
                <TextField
                  id="admin-note"
                  name="note"
                  value={values.note}
                  onChange={(e) => {
                    setNote(e.target.value);
                    handleChange(e);
                  }}
                  disabled={!isEditMode}
                  multiline
                  error={errors.note && touched.note && errors.note}
                  type="text"
                  margin="dense"
                  fullWidth
                  rows={5}
                  placeholder="Enter you notes"
                  InputProps={{ disableUnderline: true }}
                />
                {touched.note && errors.note && (
                  <FormHelperText error variant="outlined">
                    {touched.note && errors.note}
                  </FormHelperText>
                )}
              </Grid>
              <Grid
                container
                item
                xs={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <Box
                  className={classes.heading}
                  style={{ marginBottom: "10px" }}
                >
                  Contact and Email
                </Box>
                <Box>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Email
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        id="admin-email"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          handleChange(e.target.value);
                        }}
                        disabled={!isEditMode}
                        error={errors.email && touched.email && errors.email}
                        type="text"
                        margin="dense"
                        fullWidth
                        placeholder="Enter you email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error variant="outlined">
                          {touched.email && errors.email}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    alignItems="center"
                    style={{ padding: "10px" }}
                    container
                    item
                    xs={12}
                  >
                    <Grid item xs={3}>
                      Contact No
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        id="admin-contact"
                        name="contactNo"
                        value={values.contactNo}
                        onChange={(e) => {
                          setContactNo(e.target.value);
                          handleChange(e.target.value);
                        }}
                        variant="outlined"
                        margin="dense"
                        error={
                          errors.contactNo &&
                          touched.contactNo &&
                          errors.contactNo
                        }
                        fullWidth
                        disabled={!isEditMode}
                        placeholder="Enter contact number"
                        classes={{ root: classes.inputField }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone
                                fontSize="small"
                                className={classes.icon}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* {touched.contactNo && errors.contactNo && (
                        <FormHelperText error variant="outlined">
                          {touched.contactNo && errors.contactNo}
                        </FormHelperText>
                      )} */}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
};

export default AboutUs;
