import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";

import useGeneralStyle from "../../../assets/css/general";

import TabPanel from "@mui/material/Tabs";

import Specialities from "../ListOfValues/Professions/Professions";
import Ranks from "./Ranks/Ranks";

function ListOfValues() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: "1.5rem",
    },
    activeFilter: {
      backgroundColor: "white",
      borderRadius: "0px",
      color: "black",
      fontWeight: 600,
    },
    button: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row-reverse",
      paddingRight: "20px",
      backgroundColor: "#F5F5F7",
      color: "black",
    },
    inclinedBtn: {
      // width: "100%",
      width: "max-content",
      height: "44px",
    },
    inclinedBox: {
      height: "0px",
      width: "20%",
      height: "0px",
      borderBottom: "44px solid #e0e0e0",
      borderRight: "30px solid #F5F5F7",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    selectedBox: {
      borderBottom: "44px solid white",
    },
    tabsContainer: {
      margin: "20px 0 0 0",
      borderRadius: "5px",
      width: "80%",
      [theme.breakpoints.down("md")]: {
        width: "95%",
        overflow: "scroll",
      },
      [theme.breakpoints.down("sm")]: {
        width: "88%",
        overflow: "scroll",
      },
      "&::-webkit-scrollbar": {
        height: "0",
      },
    },
    tabPanel: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        overflow: "scroll",
      },
    },
  }));

  const [tab, setTab] = useState("specialities");
  const globalClasses = useGeneralStyle();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" className={classes.tabsContainer}>
            <Box
              className={clsx({
                [classes.inclinedBox]: true,
                [classes.selectedBox]: tab === "specialities",
              })}
            >
              <Button
                disableElevation
                variant="text"
                onClick={() => {
                  setTab("specialities");
                }}
                className={clsx({
                  [classes.activeFilter]: tab === "specialities",
                  [classes.inclinedBtn]: true,
                })}
              >
                Professions
              </Button>
            </Box>
            <Box
              className={clsx({
                [classes.inclinedBox]: true,
                [classes.selectedBox]: tab === "rank",
              })}
            >
              <Button
                style={{ display: "inline-flex" }}
                disableElevation
                variant="text"
                onClick={() => {
                  setTab("rank");
                }}
                className={clsx({
                  [classes.activeFilter]: tab === "rank",
                  [classes.inclinedBtn]: true,
                })}
              >
                Generic Ranks
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {tab === "rank" ? (
        <TabPanel className={classes.tabPanel} value={tab} index="ranks">
          <Ranks />
        </TabPanel>
      ) : (
        <TabPanel className={classes.tabPanel} value={tab} index="specialities">
          <Specialities />
        </TabPanel>
      )}
    </>
  );
}

export default ListOfValues;
