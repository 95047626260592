import React, { createContext, useContext } from 'react'

import { apiClient } from '../config/apiClient'
import { DEVICE_ID, USER_EMAIL, USER_ID, USER_TOKEN } from '../config/constants'

const AuthContext = createContext({
  getUserDetails: () => {},
  getUserEmail: () => {},
  getUserToken: () => {},
  getUserRole: () => {},
  logout: () => {},
})

// Declare Auth provider
function AuthProvider(props) {
  // get user email
  function getUserEmail() {
    return window.localStorage.getItem(USER_EMAIL)
  }

  // get user token
  function getUserToken() {
    return window.localStorage.getItem(USER_TOKEN)
  }

  function getUserId() {
    return window.localStorage.getItem(USER_ID)
  }

  // function getUserRole() {
  //   return window.localStorage.getItem(USER_ROLE);
  // }

  // const getUserDetails = () => window.localStorage.getItem(USER_DETAILS);
  const setUserDetails = (obj) => {
    window.localStorage.setItem(USER_EMAIL, obj.email)
    window.localStorage.setItem(USER_TOKEN, obj.secret)
    window.localStorage.setItem(USER_ID, obj.id)
    // window.localStorage.setItem(USER_ROLE, obj.role_name);
  }

  const logout = async () => {
    console.log('logout api call.')
    try {
      await apiClient('POST', 'common', 'logoutuser', {
        body: {
          user_id: getUserId(),
          user_type: "admin",
          device_id: DEVICE_ID
        },
        shouldUseDefaultToken: false,
        enableLogging: true,
      })

      window.localStorage.clear()
      window.location.pathname = '/login'
    } catch (error) {
      window.localStorage.clear()
      window.location.pathname = '/login'
    }
  }

  return (
    <AuthContext.Provider
      value={{
        getUserEmail,
        getUserToken,
        getUserId,
        setUserDetails,
        // getUserRole,
        logout,

        // login,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

// custom hook
function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }

