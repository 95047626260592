import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import { Button, InputLabel, Typography } from "@mui/material";
import MuiPhoneNumber from "mui-phone-number";
import React, { useState } from "react";

//   import { apiClient } from "../../config/apiClient";
//   import useCallbackStatus from "../../hooks/useCallbackStatus";
//   import useCancelRequest from "../../hooks/useCancelToken";
//   import Header from "../../layouts/Headr";
import TextField from "../../MuiComponent/TextField";
//   import { useSnackbar } from "notistack";
//   import { useAuth } from "../../contexts/authContext";
import { useFormik } from "formik";
//   import { contactUsSchema } from "../../validations/contactUsValidation";
//   import { getRoles } from "@testing-library/dom";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "16px",
    fontWeight: 600,
  },
  resize: {
    fontSize: "14px",
    fontWeight: 500,
    background: "#F5F5F7",
  },
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  root: {
    // border: '1px solid #ccc',
    margin: "20px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #ccc",
    padding: "1rem",
  },
  action: {
    color: theme.palette.primary.main_secondary,
    fontSize: "20px",
    textTransform: "none",
  },
  content: {
    padding: theme.spacing(4),
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5, 3),
  },
  editButton: {
    textTransform: "none",
  },
  icon: {
    color: "#C3C3C3",
  },
  error: {
    color: "red",
    marginLeft: "8px",
    fontSize: "12px",
    padding: "10px",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  // const apiSource = useCancelRequest();
  // const getCmsApiStatus = useCallbackStatus();
  // const updateCmsPages = useCallbackStatus();
  // const notification = useSnackbar();
  // const { logout } = useAuth();
  // const auth = useAuth();

  // +---------------------------- Get and Set methods definitions ----------------------------+
  const [tab, setTab] = useState("about");
  const [isEditMode, setEditMode] = useState(false);
  const [cmsData, setCmsData] = useState("Hello about cms");
  const [getEmail, setEmail] = useState("");
  const [getContactNo, setContactNo] = useState("");
  const [getNote, setNote] = useState("");
  const [aboutError, setAboutError] = useState("");

  // +------------------------------ api call for GET_CMS_PAGES -------------------------------+
  // const getCmsPages = async (apiBody) => {
  //   try {
  //     const res = await getCmsApiStatus.run(
  //       apiClient("POST", "setting", "getcmspages", {
  //         body: {
  //           user_id: localStorage.getItem("userId"),
  //           user_type: "admin",
  //         },
  //         shouldUseDefaultToken: true,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //     );
  //     const {
  //       content: { data },
  //     } = res;
  //     data && data.about_us && data.about_us.content
  //       ? setCmsData(data.about_us.content)
  //       : setCmsData({});
  //     data && data.contact_us && data.contact_us.content
  //       ? setNote(data.contact_us.content)
  //       : setNote({});
  //     data && data.contact_us_email && data.contact_us_email.content
  //       ? setEmail(data.contact_us_email.content)
  //       : setEmail({});
  //     data && data.contact_us_phone && data.contact_us_phone.content
  //       ? setContactNo(data.contact_us_phone.content)
  //       : setContactNo({});
  //   } catch (err) {
  //     if (err && err.code === 403) {
  //       logout();
  //     } else {
  //       notification.enqueueSnackbar(err.message, {
  //         variant: "err",
  //         autoHideDuration: 2000,
  //       });
  //     }
  //   }
  // };

  // +----------------------------- api call for UPDATE_CMS_PAGES -----------------------------+
  // const updateCmsData = async (apiBody) => {
  //   try {
  //     const res = await updateCmsPages.run(
  //       apiClient("POST", "setting", "managecmspages", {
  //         body: { ...apiBody },
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         isContact: true,
  //         enableLogging: false,
  //       })
  //     );
  //     const { code } = res;

  //     if (code === 200) {
  //       getCmsPages({});
  //       setEditMode(false);
  //     }

  //     notification.enqueueSnackbar(res.message, {
  //       variant: "success",
  //       autoHideDuration: 2000,
  //     });
  //   } catch (err) {
  //     if (err && err.code === 403) {
  //       logout();
  //     } else {
  //       notification.enqueueSnackbar(err.message, {
  //         variant: "err",
  //         autoHideDuration: 2000,
  //       });
  //     }
  //   }
  // };

  // const handleSubmitForAboutUs = () => {
  //   if (!cmsData) {
  //     console.log("no cms");
  //     setAboutError("About Us Required");
  //     return;
  //   }
  //   if (!cmsData.replace(/\s/g, "").length) {
  //     console.log("wrong cms");
  //     setAboutError("Enter Valid Info");
  //     return;
  //   }
  //   setAboutError("");
  //   const apiBody = {
  //     user_id: localStorage.getItem("userId"),
  //     user_type: "admin",
  //     cms_page_data: [
  //       {
  //         cms_page_name: "about_us",
  //         cms_page_content: cmsData,
  //       },
  //     ],
  //   };

  //   console.log("APIBODY Update ABOUT:", apiBody);
  //   updateCmsData(apiBody);
  // };

  // const getRoles = async () => {
  //   let apiBody = { admin_id: localStorage.getItem("userId") };
  //   console.log("APIBODY: GETROLES: ", JSON.stringify(apiBody));
  //   await updateCmsPages.run(
  //     apiClient("POST", "setting", "getroles", {
  //       body: apiBody,
  //       shouldUseDefaultToken: false,
  //       cancelToken: apiSource.token,
  //       isContact: true,
  //       enableLogging: false,
  //     }).then((result) => {
  //       console.log("REsult: ", result);
  //     })
  //   );
  // };

  // useEffect(() => {
  //   // getRoles();
  //   getCmsPages({});
  // }, []);

  // useEffect(() => {
  //   setCmsData(cmsData);
  // }, [cmsData]);

  // +-------------------- data validation and error handling using formik --------------------+

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: getEmail || "",
      contactNo: getContactNo || "",
      note: getNote || "",
    },
    //   validationSchema: contactUsSchema,
    onSubmit: () => {
      const apiBody = {
        user_id: localStorage.getItem("userId"),
        user_type: "admin",
        cms_page_data: [
          {
            cms_page_name: "contact_us",
            cms_page_content: getNote,
          },
          {
            cms_page_name: "contact_us_email",
            cms_page_content: getEmail,
          },
          {
            cms_page_name: "contact_us_phone",
            cms_page_content: getContactNo,
          },
        ],
      };
      console.log("update payload:", apiBody);
      // updateCmsData(apiBody);
    },
  });

  const { touched, errors, handleSubmit, handleChange, values, handleBlur } =
    formik;

  return (
    <>
      {/* <Header>
          <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
            Company info
          </Typography>
        </Header> */}

      <Paper elevation={0} className={classes.root}>
        <Grid style={{ borderBottom: "1px solid #c9c9c9" }} item xs={12}>
          <Box display="flex">
            <Box style={{ margin: "20px", fontSize: "20px", color: "white" }}>
              <Typography variant="h2">Contact Us</Typography>
            </Box>
            <Box
              flexGrow={1}
              alignSelf="center"
              textAlign="end"
              style={{ margin: "10px 10px", marginLeft: "10px" }}
            >
              {isEditMode ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="text"
                    style={{ marginRight: "8px", color: "white" }}
                    // onClick={() => {
                    //   setEditMode(false);
                    //   setCmsData(cmsData);
                    //   getCmsPages({});
                    //   setAboutError("");
                    // }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    // disabled={updateCmsPages.isPending}
                    // onClick={handleSubmit}
                    // onClick={() => {
                    //   tab === "about"
                    //     ? handleSubmitForAboutUs()
                    //     : handleSubmit();
                    // }}
                  >
                    {/* {updateCmsPages.isPending ? (
                      <CircularProgress size={20} className={classes.loader} />
                    ) : ( */}
                    "Submit"
                    {/* )} */}
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={() => setEditMode(true)}
                >
                  EDIT
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <div style={{ padding: "20px" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "50%",
            }}
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <InputLabel>
                  <Typography>Email</Typography>
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Email"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <InputLabel>
                  <Typography>Phone number</Typography>
                </InputLabel>
                {/* <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                /> */}
                <MuiPhoneNumber
                  defaultCountry={"in"}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Enter here"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
                ,
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <InputLabel>
                  <Typography>Contact Us</Typography>
                </InputLabel>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Write here..."
                  multiline
                  rows={5}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Paper>
    </>
  );
};

export default AboutUs;
