import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import userIcon from "../../assets/Dashboard/userIcon.svg";
import networkIcon from "../../assets/Dashboard/networkIcon.svg";
import dutyIcon from "../../assets/Dashboard/dutyIcon.svg";
import broadcastIcon from "../../assets/Dashboard/broadcastIcon.svg";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard({ data }) {
  return (
    <Grid container spacing={2}>
      {
        ['1'].map((item, index) => (
          <Grid item sx={6}>
            <Card
              sx={{
                width: "100%",
                minWidth: "280px",
                height: "200px",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: 0,
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Total Members{" "}
                </Typography>


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{ fontSize: "42px" }}
                    color="#202224"
                    style={{ display: "flex" }}
                  >
                    {/* {data[0].total_tickets} */}
                    40,689
                  </Typography>
                  <img src={userIcon} style={{ height: '60px', width: '60px' }}></img>

                  {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Active
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                      30,000
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Inactive
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#FD096F" gutterBottom>
                      10,689
                    </Typography>
                  </div>

                </div>
                <div>
                  <Typography>
                    <TrendingUpIcon style={{ color: '#00C3B8' }} /> <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last Q3
                  </Typography>

                </div>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ flexGrow: 1, padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
                  </Grid>
                  {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
                </Grid>
              </Box>
              {/* </CardActions> */}
            </Card>
          </Grid>
        ))
      }
      {
        ['1'].map((item, index) => (
          <Grid item sx={6}>
            <Card
              sx={{
                width: "100%",
                minWidth: "280px",
                height: "200px",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: 0,
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Total Networks{" "}
                </Typography>


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{ fontSize: "42px" }}
                    color="#202224"
                    style={{ display: "flex" }}
                  >
                    12
                  </Typography>
                  <img src={networkIcon} style={{ height: '60px', width: '60px' }}></img>
                  {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Active
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                      10
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Inactive
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#FD096F" gutterBottom>
                      2
                    </Typography>
                  </div>

                </div>
                <div>
                  <Typography>
                    <TrendingDownIcon style={{ color: '#FD096F' }} /> <span style={{ color: '#FD096F' }}>8.5% </span>Up from Last Q3
                  </Typography>

                </div>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ flexGrow: 1, padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
                  </Grid>
                  {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
                </Grid>
              </Box>
              {/* </CardActions> */}
            </Card>
          </Grid>
        ))
      }
      {
        ['1'].map((item, index) => (
          <Grid item sx={6}>
            <Card
              sx={{
                width: "100%",
                minWidth: "280px",
                height: "200px",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: 0,
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Broadcast Engagement rate{" "}
                </Typography>


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{ fontSize: "42px" }}
                    color="#202224"
                    style={{ display: "flex" }}
                  >
                    70%
                  </Typography>
                  <img src={broadcastIcon} style={{ height: '60px', width: '60px' }}></img>
                  {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Read
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                      1200
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Unread
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#F29006" gutterBottom>
                      300
                    </Typography>
                  </div>

                </div>
                <div>
                  <Typography>
                    <TrendingUpIcon style={{ color: '#00C3B8' }} /> <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last week
                  </Typography>

                </div>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ flexGrow: 1, padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
                  </Grid>
                  {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
                </Grid>
              </Box>
              {/* </CardActions> */}
            </Card>
          </Grid>
        ))
      }
      {
        ['1'].map((item, index) => (
          <Grid item sx={6}>
            <Card
              sx={{
                width: "100%",
                minWidth: "280px",
                height: "200px",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: 0,
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Total On-duty{" "}
                </Typography>


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{ fontSize: "42px" }}
                    color="#202224"
                    style={{ display: "flex" }}
                  >
                    6,000
                  </Typography>
                  <img src={dutyIcon} style={{ height: '60px', width: '60px' }}></img>
                  {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Day
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                      5,100
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                      Night
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#F29006" gutterBottom>
                      900
                    </Typography>
                  </div>

                </div>
                <div>
                  <Typography>
                    <TrendingUpIcon style={{ color: '#00C3B8' }} /> <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last week
                  </Typography>

                </div>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ flexGrow: 1, padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
                  </Grid>
                  {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
                </Grid>
              </Box>
              {/* </CardActions> */}
            </Card>
          </Grid>
        ))
      }




    </Grid>
  );
}
