import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import ListOfValues from "./ListOfValues/ListOfValues";
import Header from "../../layouts/Header";

const useStyles = makeStyles((theme2) => ({
  activeFilter: {
    borderBottom: "2px solid black ",
    borderRadius: "0px",
    color: "white",
    fontWeight: 600,
  },
}));

function Settings() {
  const classes = useStyles();
  const [tab, setTab] = useState("ListOfValues");

  useEffect(() => {
    setTab("ListOfValues");
  }, []);

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Settings
        </Typography>
      </Header>
      {/* <Box
        display="flex"
        style={{
          backgroundColor: "white",
          color: "black",
          margin: "20px 0",
          borderRadius: "5px",
          height: "44px",
        }}
      >
        <Box style={{ margin: "0px 10px 0px 20px" }}>
          <Button
            color="primary"
            disableElevation
            style={{ marginLeft: "20px", height: "44px", color: "black" }}
            variant="text"
            onClick={() => {
              setTab("ListOfValues");
            }}
            className={clsx({
              [classes.activeFilter]: tab === "ListOfValues",
            })}
          >
            List Of Values
          </Button>
        </Box>
      </Box> */}

      {tab === "ListOfValues" && <ListOfValues />}
    </>
  );
}

export default Settings;
