import { Typography } from "@mui/material";
import React, { useRef } from "react";
import ProgressCard from "../ProgressCard/ProgressCard";
import WhiteCardWrapper from "../UI/WhiteCardWrapper/WhiteCardWrapper";
import "./CentralOverview.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import MidOverview from "../MidOverview/MidOverview";
import NotificationCard from "../NotificationCard/NotificationCard";
import Header from "./../../components/Header/Header";
// import AppBarModule from "../Header/AppBar";

const CentralOverview = () => {
  const sliderRef = useRef(null);
  const notificationSliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const nextNoti = () => {
    notificationSliderRef.current.slickNext();
  };

  const previousNoti = () => {
    notificationSliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <>
      <div>
        <Header />
        <MidOverview />
        {/* <div className="sectionHeading flex-aicjcsb">
      <Typography sx={{ paddingTop: "10px" }} variant="h1">
        Notifications
      </Typography>
      <div style={{ textAlign: "center" }} className="flex-aic flexgap1">
        <span onClick={previousNoti} style={{ cursor: "pointer" }}>
          <ArrowBackIosNewRoundedIcon fontSize=".7rem" />
        </span>
        <span onClick={nextNoti} style={{ cursor: "pointer" }}>
          <ArrowForwardIosRoundedIcon fontSize=".7rem" />
        </span>
      </div>
      </div>

      <div className="ProgressCardsSliderArea">
        <Slider ref={notificationSliderRef} {...settings}>
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
        </Slider>
      </div>
      <div className="sectionHeading flex-aicjcsb">
        <Typography variant="h1">Meetings</Typography>
        <div style={{ textAlign: "center" }} className="flex-aic flexgap1">
          <span onClick={previous} style={{ cursor: "pointer" }}>
            <ArrowBackIosNewRoundedIcon fontSize=".7rem" />
          </span>
          <span onClick={next} style={{ cursor: "pointer" }}>
            <ArrowForwardIosRoundedIcon fontSize=".7rem" />
          </span>
        </div>
      </div>

      <div className="ProgressCardsSliderArea">
        <Slider ref={sliderRef} {...settings}>
          <WhiteCardWrapper>
            <ProgressCard />
          </WhiteCardWrapper>
          <WhiteCardWrapper>
            <ProgressCard />
          </WhiteCardWrapper>
          <WhiteCardWrapper>
            <ProgressCard />
          </WhiteCardWrapper>
          <WhiteCardWrapper>
            <ProgressCard />
          </WhiteCardWrapper>
          <WhiteCardWrapper>
            <ProgressCard />
          </WhiteCardWrapper>
        </Slider>
      </div> */}
      </div>
    </>

  );
};

export default CentralOverview;
