import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText, CircularProgress } from "@mui/material";

export default function FormDialog({
  setCreate,
  profession,
  setProfession,
  createProfession,
  currentRow,
  setCurrentRow,
  apiStatus
}) {
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCreate(false);
    setCurrentRow(null);
  };

  const handleSave = () => {
    if (!profession) {
      setErrors("Please enter sub specialty");
    } else {
      if (currentRow) {
        createProfession("update");
      } else {
        createProfession("create");
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth onClose={handleClose}>
        <DialogTitle>
          {currentRow ? "Edit Rank" : "Create New Rank"}
        </DialogTitle>
        <DialogContent>
          <span>Rank Name</span>
          <TextField
            variant="outlined"
            autoFocus
            error={errors}
            id="name"
            placeholder="Enter here..."
            value={profession}
            fullWidth
            onChange={(e) => {
              setErrors(null);
              setProfession(e.target.value);
            }}
          />
          <FormHelperText style={{ color: "red" }}>{errors}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSave}
            style={{ background: "black", color: "white" }}
          >
            {apiStatus.isPending ? (
              <CircularProgress size={23} style={{ color: "#BAC8FF" }} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
