import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { Select, Menu, MenuItem, FormControl } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { useAuth } from "../../providers/AuthProvider";
import useCancelRequest from "../../hooks/useCancelToken";
import { useSnackbar } from "notistack";
import { format, subDays } from "date-fns";
import styles from "./DatePickerStyles.module.css";
import { Backdrop, CircularProgress } from "@material-ui/core";

const Graph = ({ data }) => {
  const [role, setRole] = useState("daily");
  const [dataLine, setDataLine] = useState([]);
  const [nameLine, setNameLine] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const auth = useAuth();
  const apiSource = useCancelRequest();
  const getRankListApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [userData, setUserData] = useState(data);

  const handleChange = (e) => {
    setRole(e.target.value);
  };
  const GraphOptions = {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    noData: {
      text: "No Data Found",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: nameLine,
    },
    markers: {
      size: 5,
      colors: undefined,
      strokeColors: '#fff',
      strokeWidth: 5,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    colors: ["#4379EE", "#FFFFFF"],
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 1,
      dashArray: 0,
    },
    style: {
      fontSize: "12px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 500,
      colors: undefined,
    },
    grid: {
      show: true,
      borderColor: "#EBEDF7",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      intersect: true,
      shared: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };

  // Function to convert and format the data

  const getDashoardMetrics = async (apiBody) => {
    console.log("isnide");
    console.log(apiBody);
    await getRankListApiStatus.run(
      apiClient("POST", "admin", "getdashboardmetrics", {
        body: {
          ...apiBody,
        },
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          setUserData(result.content.user_data);
        })
        .catch((err) => {
          if (err && err.code === 403) {
            auth.logout();
          } else {
            notification.enqueueSnackbar(err.message, {
              variant: "err",
              autoHideDuration: 2000,
            });
          }
        })
    );
  };
  useEffect(() => {
    var d1 = new Date();
    var d2 = new Date();
    setStartDate(d1.setDate(d1.getDate() - 30));
    setEndDate(d2.setDate(d2.getDate() - 1));
  }, []);

  //Weekly

  function aggregateDataByWeek(data) {
    // Helper function to get week number in month
    const getWeekOfMonth = (date) => {
      const firstDayOfMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).getDay();
      const offsetDate = date.getDate() + firstDayOfMonth - 1; // Adjusted for the start day of the week
      return Math.floor(offsetDate / 7) + 1;
    };

    // Helper function to format the week label
    const formatWeekLabel = (weekNumber, month, year) => {
      const suffix = ["st", "nd", "rd", "th"];
      const weekStr = weekNumber < 4 ? suffix[weekNumber - 1] : suffix[3];
      return `${weekNumber}${weekStr} W, ${month} ${year.toString().slice(-2)}`;
    };

    const result = [];
    const weeklyCounts = {};

    data.forEach((item) => {
      const date = new Date(item.date);
      const weekOfMonth = getWeekOfMonth(date);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      const key = `${weekOfMonth}-${month}-${year}`;

      if (!weeklyCounts[key]) {
        weeklyCounts[key] = { week: weekOfMonth, month, year, count: 0 };
      }

      weeklyCounts[key].count += parseInt(item.count, 10);
    });

    for (const key in weeklyCounts) {
      const { week, month, year, count } = weeklyCounts[key];
      result.push({
        week: formatWeekLabel(week, month, year),
        value: count,
      });
    }

    return result;
  }

  // monthly

  function aggregateDataByMonth(data) {
    const monthlyCounts = {};

    data.forEach((item) => {
      const date = new Date(item.date);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      const key = `${month} ${year}`;

      if (!monthlyCounts[key]) {
        monthlyCounts[key] = 0;
      }

      monthlyCounts[key] += parseInt(item.count, 10);
    });

    const result = Object.keys(monthlyCounts).map((key) => ({
      month: key,
      value: monthlyCounts[key],
    }));

    return result;
  }

  useEffect(() => {
    if (role === "daily") {
      // const yearlyFormatData = convertToYearlyFormat(data);
      // console.log(yearlyFormatData);
      let names = [];
      let datas = [];
      userData.map((item, index) => {
        names.push(item.date);
        datas.push(item.count);
      });

      setNameLine(names);
      setDataLine(datas);
    }
    if (role === "monthly") {
      // const monthlyFormatData = convertToMonthlyFormat(
      //   userData
      // );
      console.log(aggregateDataByMonth(userData));

      let dm = aggregateDataByMonth(userData);
      let names = [];
      let datas = [];
      dm.map((item, index) => {
        names.push(item.month);
        datas.push(item.value);
      });

      setNameLine(names);
      setDataLine(datas);
    } else if (role === "weekly") {
      console.log("weekly");
      console.log(aggregateDataByWeek(userData));
      let d = aggregateDataByWeek(userData);
      // const weeklyFormattedData = convertDataToWeeklyFormat(
      //   userData.sort((a, b) => new Date(a.date) - new Date(b.date))
      // );
      // // console.log(weeklyFormattedData);
      let names = [];
      let datas = [];
      d.map((item, index) => {
        names.push(item.week);
        datas.push(item.value);
      });

      setNameLine(names);
      setDataLine(datas);
    }
  }, [role, userData]);
  const series = [
    {
      name: "User Count",
      data: dataLine,
    },
    // {
    //   name: "Task-2",
    //   data: ["2", "3", "4", "3", "1"],
    // },
  ];

  return (
    <>
      <Card
        sx={{
          width: "100%",
          minWidth: "400px",
          height: "400px",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: 0,
        }}
      >
        <CardContent>
          <Grid container spacing={2} style={{ padding: "10px" }}>
            <Grid item sm={5}>
              <div>
                <Typography style={{ color: '#202224', fontWeight: '700', fontSize: '24px' }}>
                  Overview Analytics</Typography>
              </div>
            </Grid>
            <Grid item sm={7}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Typography>Start Date :</Typography>
                <DatePicker
                  customInput={
                    <input
                      style={{
                        height: "38px",
                        borderRadius: "5px",
                        textAlign: "center",
                        width: "120px",
                      }}
                    />
                  }
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);

                    // Set to start of the day
                    date.setHours(0, 0, 0, 0);

                    // Convert back to epoch in seconds
                    const startOfDayEpoch = Math.floor(date.getTime() / 1000);
                    let apiBody = {
                      name: ["user_count"],
                      start_date: startOfDayEpoch,
                      end_date: Math.floor(endDate / 1000),
                    };
                    getDashoardMetrics(apiBody);
                  }}
                />
                <Typography>End Date :</Typography>
                <DatePicker
                  customInput={
                    <input
                      style={{
                        height: "38px",
                        borderRadius: "5px",
                        textAlign: "center",
                        width: "120px",
                      }}
                    />
                  }
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    date.setHours(23, 59, 59, 999);
                    // Convert back to epoch in seconds
                    const endOfDayEpoch = Math.floor(date.getTime() / 1000);
                    let apiBody = {
                      name: ["user_count"],
                      start_date: Math.floor(startDate / 1000),
                      end_date: endOfDayEpoch,
                    };
                    getDashoardMetrics(apiBody);
                  }}
                />
                <Typography>Oraganise :</Typography>

                <FormControl sx={{ m: 1, width: "120px" }} size="small">
                  <Select
                    id="role"
                    name="role"
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    value={role === null ? "none" : role}
                  >
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                    <MenuItem value={"weekly"}>Weekly</MenuItem>
                    <MenuItem value={"daily"}>Daily</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Card
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: 0,
            }}
          >
            <Chart
              options={GraphOptions}
              series={series}
              type="area"
              height="300px"
              width="100%"
            />
          </Card>
        </CardContent>
      </Card>
    </>
  );
};

export default Graph;
