import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Chart from "react-apexcharts";

const bull = (
    <Box
        component="span"
        sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
        •
    </Box>
);



export default function BasicCard({ data }) {
    const GraphOptions = {
        chart: {
            type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]

    };
    const series = [44, 55, 41, 17, 15];
    const seriesLine = [
        {
            name: "Task-2",
            data: ["2", "3", "4", "3", "1"],
        },
        {
            name: "Task-1",
            data: ["1", "7", "3", "1", "2"],
        },
    ];
    const GraphOptionsLine = {
        chart: {
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        noData: {
            text: "No Data Found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: "20px",
                fontFamily: undefined,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['2001', '2002', '2003', '2004', '2005'],
        },
        markers: {
            size: 5,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 5,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        },
        colors: ["#DBA5FF", "#FF8F6D"],
        stroke: {
            show: true,
            curve: "smooth",
            lineCap: "butt",
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        style: {
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            colors: undefined,
        },
        grid: {
            show: true,
            borderColor: "#EBEDF7",
            strokeDashArray: 0,
            position: "back",
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },

    };
    return (
        <Grid container spacing={2}>
            <Grid item sx={6}>
                <Card
                    sx={{
                        width: "100%",
                        minWidth: "280px",
                        height: "300px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: 0,
                    }}
                >
                    <CardContent>
                        <Typography sx={{ fontSize: "24px", fontWeight: '700' }} color="#202224" gutterBottom>
                            Customers{" "}
                        </Typography>
                        <div>
                            <Chart
                                options={GraphOptions}
                                series={series}
                                type="donut"
                                width="100%"
                            />
                        </div>



                    </CardContent>

                </Card>
            </Grid>
            <Grid item sx={6}>
                <Card
                    sx={{
                        width: "100%",
                        minWidth: "280px",
                        height: "300px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: 0,
                    }}
                >
                    <CardContent>
                        <Typography sx={{ fontSize: "24px", fontWeight: '700' }} color="#202224" gutterBottom>
                            Analytics{" "}
                        </Typography>
                        <div>
                            <Chart
                                options={GraphOptionsLine}
                                series={seriesLine}
                                type="line"
                                width="100%"
                            />
                        </div>



                    </CardContent>

                </Card>
            </Grid>




        </Grid>
    );
}
