import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";

import OuterLayout from "../../layouts/login/OuterLayout";
import TextField from "../../MuiComponent/TextField";
import useCancelRequest from "../../hooks/useCancelToken";

import { useAuth } from "../../providers/AuthProvider";
import { apiClient } from "../../config/apiClient";
import { forgotPasswordValidation } from "../../validations/login-validate";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { validateEmail } from "../../hooks/Validation";

const useStyles = makeStyles((theme) => ({
    mainHeading: {
        fontSize: "24px",
        fontWeight: 500,
        color: "white",
        padding: "10% 10% 0 10%",
    },
    subHeading: {
        fontSize: "22px",
        fontWeight: 600,
        color: "white",
        padding: "7% 0 7% 10%",
    },
    emailField: {
        padding: "0 10% 0% 10%",
        height: "60px",
    },
    passwordField: {
        margin: "0% 10% 1% 10%",
        height: "40px",
        width: "80%",
    },
    forgotPassword: {
        margin: "2% 10% 0 0",
        textDecoration: "none",
        color: "white",
        // color: theme.palette.custom.heading,
        fontSize: "14px",
        fontWeight: 400,
    },
    loginButton: {
        marginTop: "8%",
        marginBottom: "8%",
        fontWeight: "600",
        borderRadius: "10px",
        width: "80%",
        height: "50%",
    },
    icon: {
        backgroundColor: "#141522",
    },

    RecaptchaDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "1rem auto 0",
    },
}));

function ForgotPassword(props) {
    let navigate = useNavigate();
    let auth = useAuth();
    const apiSource = useCancelRequest();
    const forgotApiStatus = useCallbackStatus();
    const notification = useSnackbar();
    const classes = useStyles();

    let { values, touched, errors, handleBlur, submitForm, handleChange } =
        useFormik({
            initialValues: {
                email: "",
            },
            onSubmit: () => {
                forgotPassword();
            },
            validationSchema: forgotPasswordValidation,
        });

    let forgotPassword = async () => {
        let apiBody = {
            email: values.email,
            user_type: "admin",
            action_type: "delete_account",
        };

        try {
            const result = await forgotApiStatus.run(
                apiClient("POST", "common", "/unauth/sendresendotp", {
                    body: apiBody,
                    shouldUseDefaultToken: true,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            let {
                content: { data, wait_time },
            } = result;

            if (data)
                notification.enqueueSnackbar("Email sent successfully", {
                    variant: "success",
                });
            window.localStorage.setItem(
                "otpData",
                JSON.stringify({
                    from: "delete-account",
                    email: values.email,
                    time: wait_time,
                })
            );
            navigate("/otp-verification", {
                from: "delete-account",
                email: values.email,
                time: wait_time,
            });
        } catch (error) {
            //console.log("Forgot Password Component Error: ", error);

            notification.enqueueSnackbar(error?.userMessage, {
                variant: "error",
            });
        }
    };

    useEffect(() => {
        if (auth.getUserId()) {
            navigate("/home");
        }
    });
    const validate = () => {
        if (!validateEmail(values.email)) {
            notification.enqueueSnackbar("Please enter valid email", {
                variant: "error",
            });
        } else {
            submitForm();
        }
    };
    return (
        <div className="forgotpassword-content-shift">
            <OuterLayout Welcometext={true} is_back_button={false} on_back="/login">
                <Box className={classes.mainHeading}>
                    Request to delete account
                    <Box
                        style={{
                            fontWeight: 300,
                            fontSize: "13px",
                            opacity: 0.8,
                            marginTop: "10px",
                        }}
                    >
                        Please enter your registered email address to delete <br /> your
                        account
                    </Box>
                </Box>

                <Box style={{ padding: "7% 0 0% 0%" }}>
                    <TextField
                        id="email"
                        name="email"
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        value={values.email}
                        placeholder="Email"
                        className={classes.emailField}
                    />
                </Box>

                <Box style={{ textAlign: "center" }}>
                    <Button
                        disabled={forgotApiStatus.isPending}
                        // onClick={submitForm}
                        onClick={() => {
                            validate();
                        }}
                        variant="contained"
                        className={classes.loginButton}
                        color="primary"
                    >
                        {forgotApiStatus.isPending ? (
                            <CircularProgress size={23} color="secondary" />
                        ) : (
                            "Next"
                        )}
                    </Button>
                </Box>
            </OuterLayout>
        </div>
    );
}

export default ForgotPassword;
