import * as yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const feedbackValidation = yup.object().shape({
    email: yup
        .string()
        .email()
        .required("Email is required")
        .typeError("Email is required"),
    first_name: yup
        .string()
        .required("First name is required")
        .typeError("First name is required"),
    last_name: yup
        .string()
        .required("Last name is required")
        .typeError("Last name is required"),
    phone_number: yup
        .string()
        .required("Phone number is required")
        .typeError("Phone number is required")
        .matches(phoneRegExp, 'Phone number is not valid'),
    organization_name: yup
        .string()
        .required("oraganization name is required")
        .typeError("oraganization name is required"),
    job_title: yup
        .string()
        .required("Job Title is required")
        .typeError("Job Title is required"),

});

export { feedbackValidation }