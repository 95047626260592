import * as yup from "yup";
import { PODCAST_DESCRIPTION_LIMIT, PODCAST_TITLE_LIMIT } from "../config/constants";

const podcastValidation = yup.object().shape({
    title: yup
        .string()
        .max(PODCAST_TITLE_LIMIT || 35)
        .trim()
        .required("Title is Required")
        .typeError("Title is Required"),
    description: yup
        .string()
        .trim()
        .max(PODCAST_DESCRIPTION_LIMIT || 200)
        .required("Description is Required")
        .typeError("Description is Required"),
    podcast_media_data: yup.object({
        name: yup
            .string()
            .required("File is required")
            .typeError("File is required"),
    }),
    sentiment_ids: yup
        .array()
        .min(1, "Select atleast 1 category")
        .max(3, "Max 3 category allowed")
        .required("Category is required")
        .typeError("Category is required"),
});
const articleFeedbackForm = yup.object().shape({
    reason: yup
      .string()
      .max(100, "Max 100 character allowed ")
      .required("Please enter the reason")
      .typeError("Please enter the reason"),
  });

export {
    articleFeedbackForm, podcastValidation
};
