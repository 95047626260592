import { TextField, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useFormik } from "formik";
import * as React from 'react';
import BannerImage from "./Banner.png";
import MedicalCirclesLogo from "./Logo.png";
import { feedbackValidation } from './validation';
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { lineHeight, padding } from '@mui/system';
import { isMobile } from "react-device-detect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
    inputTitle: {
        fontSize: '24px',
        fontWeight: '700',
        paddingBottom: '10px',
        color: '#00172D',
        lineHeight: '20px'
    },
    inputField: {
        backgroundColor: '#F6F6F6',
    }

}));


export default function ButtonAppBar() {
    const classes = useStyles();
    let navigate = useNavigate();


    let { values, touched, errors, handleBlur, submitForm, handleChange } =
        useFormik({
            initialValues: {
                first_name: "",
                last_name: "",
                email: "",
                organization_name: "",
                job_title: "",
                phone_number: ""
            },
            onSubmit: (values) => {
                window.localStorage.setItem("name", values.first_name)
                window.location.href = "/ThankYou"
                // forgotPassword();
            },
            validationSchema: feedbackValidation,
        });
    const validate = () => {

        submitForm();

    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: 'white' }}>

                    <img src={MedicalCirclesLogo} style={{ height: '97px', width: '307px' }}></img>
                </Toolbar>

            </AppBar>
            <div style={{
                display: 'flex', justifyContent: 'center', backgroundColor: 'white'
            }}>
                {
                    isMobile ? (
                        <img src={BannerImage} style={{ height: '250px', width: '350px' }}></img>

                    ) : (
                        <img src={BannerImage} style={{ height: '500px', width: '700px' }}></img>

                    )
                }
            </div>
            <div style={{
                padding: '20px', backgroundColor: 'white'
            }}>
                <Typography style={{ fontWeight: '600', fontSize: '35px', color: '#00172D' }}>
                    Register your interest
                </Typography>
                <Typography style={{ fontWeight: '600', fontSize: '20px', color: '#00172D' }}>
                    Please complete the form below to register your interest for MedicalCircles.
                </Typography>


            </div>
            <div style={{
                padding: '30px',
                backgroundColor: 'white'
            }}  >
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        First Name
                    </Typography>
                    <TextField
                        id="first_name"
                        name="first_name"
                        error={touched.first_name && errors.first_name}
                        helperText={touched.first_name && errors.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        value={values.first_name}
                        placeholder="Enter your first name"
                        className={classes.inputField}
                    />
                </Box>
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        Last Name
                    </Typography>
                    <TextField
                        id="last_name"
                        name="last_name"
                        error={touched.last_name && errors.last_name}
                        helperText={touched.last_name && errors.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        value={values.last_name}
                        placeholder="Enter your last name"
                        className={classes.inputField}
                    />
                </Box>
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        Email
                    </Typography>
                    <TextField
                        id="email"
                        name="email"
                        error={touched.email && errors.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        type='email'
                        value={values.email}
                        placeholder="Enter your email"
                        className={classes.inputField}
                    />
                </Box>
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        Organization Name
                    </Typography>
                    <TextField
                        id="organization_name"
                        name="organization_name"
                        error={touched.organization_name && errors.organization_name}
                        helperText={touched.organization_name && errors.organization_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        value={values.organization_name}
                        placeholder="Enter your organization name"
                        className={classes.inputField}
                    />
                </Box>
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        Job Title
                    </Typography>
                    <TextField
                        id="job_title"
                        name="job_title"
                        error={touched.job_title && errors.job_title}
                        helperText={touched.job_title && errors.job_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        value={values.job_title}
                        placeholder="Enter your job title"
                        className={classes.inputField}
                    />
                </Box>
                <Box style={{ paddingBottom: '30px' }}>
                    <Typography className={classes.inputTitle}>
                        Phone number
                    </Typography>
                    <TextField
                        id="phone_number"
                        name="phone_number"
                        error={touched.phone_number && errors.phone_number}
                        helperText={touched.phone_number && errors.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        value={values.phone_number}
                        placeholder="Enter your phone number"
                        className={classes.inputField}
                    />
                </Box>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={submitForm} variant='contained' style={{ borderRadius: "16px", color: 'white', backgroundColor: '#A7A7A7' }}>
                        Submit
                    </Button>
                </div>
            </div>

        </Box>
    );
}
