import { Box, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Typography } from "@mui/material";
import React, { useState } from "react";

import { OutlinedInput } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import useGeneralStyle from "../../assets/css/general";
// import Header from "../layouts/Header";
import AdminUsersTable from "./AdminUsers";
import ProfessionalUserTable from "./ProfessionalUsers";
import ProfessionalUsers from "./ProfessionalUsers";
import Header from "../../layouts/Header"

const useStyles = makeStyles((theme) => ({
  inputOutlined: {
    padding: "10.5px 14px",
  },
  icon: {
    fill: "black",
  },
  // typeDropdownContainer: {
  //   alignItems: "center",
  //   [theme.breakpoints.down("xs")]: {
  //     flexDirection: "column",
  //     paddingTop: "10px",
  //   },
  // },
}));

function UserManagement() {
  const [userType, setUserType] = useState("admins");
  const navigate = useNavigate();
  const classes = useStyles();
  const globalClasses = useGeneralStyle();

  const handleUserType = (e) => {
    setUserType(e.target.value);
  };

  // useEffect(() => {
  //   var path = history.location.pathname;
  //   if (path === "/users") {
  //     setUserType("admins");
  //   } else if (path === "/users/admins") {
  //     setUserType("admins");
  //   } else if (path === "/users/professionals") {
  //     setUserType("professionals");
  //   } else {
  //     setUserType("admins");
  //   }
  // }, []);

  return (
    <>
    <Header>
      <Box
        // className={classes.typeDropdownContainer}
        flexGrow={1}
        display="flex"
      >
        <InputLabel
          className={globalClasses.filterLabel}
          style={{ marginTop: "10px" }}
        >
          <Typography variant="h2">Select User Type:</Typography>
        </InputLabel>
        <Select
          style={{
            height: "40px",
            fontSize: "14px",
            marginLeft: "18px",
            width: "150px",
            color: "black",
            backgroundColor: "#fff",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          input={<OutlinedInput classes={{ input: classes.inputOutlined }} />}
          value={userType}
          variant="outlined"
          onChange={handleUserType}
          inputProps={{
            classes: {
              icon: classes.icon,
              color: "black",
            },
          }}
        >
          <MenuItem value="admins" style={{ backgroundColor: "#fff" }}>
            <Typography sx={{ color: "black" }}>All Admins</Typography>
          </MenuItem>
          <MenuItem value="professionals" style={{ backgroundColor: "#fff" }}>
            <Typography sx={{ color: "black" }}>All Users</Typography>
          </MenuItem>
        </Select>
      </Box>
      </Header>
      <div style={{ marginTop: "20px" }}>
        {userType === "admins" ? (
          <AdminUsersTable />
        ) : (
          <ProfessionalUsers />
        )}
      </div>
    </>
  );
}

export default UserManagement;
