import {
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { drawerWidth } from "../config/constants";
import { useMenuCtx } from "../contexts/MenuContext";
import useCallbackStatus from "../hooks/useCallbackStatus";
import useCancelRequest from "../hooks/useCancelToken";
import { useAuth } from "../providers/AuthProvider";
import Head from "../components/Header/Header"
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuPaper: {
    top: "60px !important",
    [theme.breakpoints.down("md")]: {
      left: "90% !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "80% !important",
    },
    [theme.breakpoints.down("xs")]: {
      left: "70% !important",
    },
  },
  appBar: {
    backgroundColor: "#F5F5F7",
    color: "black",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "10px",
    },
  },
  toolbar: {
    height: '1.5rem'
  },
}));

function Header(props) {
  const menuContext = useMenuCtx();
  const classes = useStyles();
  const [popOptions, setPopOptions] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const configApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();

  const handleOptionsMenu = () => {
    setPopOptions(!popOptions);
  };

  //   const getConfigs = async (id) => {
  //     await configApiStatus.run(
  //       apiClient("POST", "common", "getconfigs", {
  //         body: {
  //           user_id: auth.getUserId(),
  //           user_type: "admin",
  //         },
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       }).then((result) => {
  //         const {
  //           content: { data },
  //         } = result;
  //         debugger
  //         const isChatEncryptionEnabled = data.filter(item => item.id === "is_chat_encryption_enabled")[0];
  //         window.localStorage.setItem("is_chat_encryption_enabled", isChatEncryptionEnabled.value);
  //       })
  //     );
  //   };

  //   useEffect(() => {
  //     if (!window.localStorage.getItem("is_chat_encryption_enabled"))
  //       getConfigs();
  //   }, [])

  return (


    <Head />

  );
}

export default Header;
