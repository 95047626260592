import React from "react";
import CentralOverview from "../../components/CentralOverview/CentralOverview";
import TaskToday from "../../components/TaskToday/TaskToday";
import WhiteCardWrapper from "../../components/UI/WhiteCardWrapper/WhiteCardWrapper";
import AppBarModule from "../../components/Header/AppBar";
import { Typography } from "@mui/material";

const Overview = () => {
  return (
    // <div className="flex-aifsjcsb flexgap2">
    <>
      {/* <AppBarModule /> */}
      <div>
        <Typography style={{ color: '#202224', fontWeight: '700', fontSize: '32px', marginBottom: '20px' }}>
          Dashboard Overview
        </Typography>
        <CentralOverview />
        {/* <WhiteCardWrapper>
        <TaskToday />
      </WhiteCardWrapper> */}
      </div>
    </>

  );
};

export default Overview;
