import {
  Box,
  Button,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import _ from "lodash";
import OuterLayout from "../../layouts/login/OuterLayout";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
import { apiClient } from "../../config/apiClient";

import Timer from "react-compound-timer/build";
import OtpInput from "react-otp-input";
// import { menu } from "../../menu";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    // color: theme.palette.custom.heading,
    fontSize: "14px",
    fontWeight: 600,
  },
  mainHeading: {
    fontSize: "30px",
    fontWeight: 600,
    color: "white",
    padding: "1.5rem 10% 1rem",
  },
  subHeading: {
    fontSize: "13px",
    fontWeight: 400,
    opacity: "69%",
    color: "white",
    padding: "0% 10% 7% 10%",
  },
  otpBox: {
    width: "80%",
    margin: "0 10% 5% 10%",
  },
  otpInput: {
    margin: "1rem auto",
    outline: "none",
    border: "none",
  },
  buttons: {
    margin: "1% auto 10%",
    // width: "80%",
    // display: "flex",
    // flexDirection: "column",
    // height: "10%",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    height: "20%",
  },
  btn: {
    // marginTop: "5%",
    // borderRadius: "8px",
    // height: "50%",
    // color: "white",
    margin: "2%",
    borderRadius: "10px",
    height: "50%",
  },
}));

function OtpVerification() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const auth = useAuth();
  let formik = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: () => {
      if (formik.values.otp === "") {
        notification.enqueueSnackbar("OTP is required", {
          variant: "error",
        });
      } else {
        if (JSON.parse(window.localStorage.getItem("otpData")).from === "MFA")
          validateOtp({
            email: JSON.parse(window.localStorage.getItem("otpData")).email,
            otp: formik.values.otp,
            entity_type: "patient",
            action_type: "login",
            // device_id: DEVICE_ID,
            // device_name: DEVICE_NAME,
          });
        else {
          if (
            JSON.parse(window.localStorage.getItem("otpData")).from ===
            "delete-account"
          ) {
            validateOtp({
              email: JSON.parse(window.localStorage.getItem("otpData")).email,
              otp: formik.values.otp,
              user_type: "admin",
              action_type: "delete_account",
            });
          } else {
            validateOtp({
              email: JSON.parse(window.localStorage.getItem("otpData")).email,
              otp: formik.values.otp,
              entity_type: "patient",
              action_type: "forgot_password",
            });
          }
        }
      }
    },

    // validationSchema: otpValidation,
  });
  const [resendTimerValue, setResendTimerValue] = useState(
    1000
  );

  const notification = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const apiSource = useCancelRequest();
  const sendOtpApiStatus = useCallbackStatus();
  const ValidateOtpApiStatus = useCallbackStatus();

  let { values, touched, errors, handleBlur, submitForm, handleChange } =
    useFormik({
      initialValues: {
        email: "",
      },
    });

  let resendOtp = async (timerActions) => {
    let apiBody = {};
    if (JSON.parse(window.localStorage.getItem("otpData")).from === "MFA") {
      apiBody = {
        user_id: JSON.parse(window.localStorage.getItem("otpData")).user_id,
        entity_type: "patient",
        action_type: "login",
        // device_id: DEVICE_ID,
        // socialData: socialData,
      };
    } else
      apiBody = {
        email: JSON.parse(window.localStorage.getItem("otpData")).email,
        user_type: "admin",
        action_type: "delete_account",
      };

    try {
      const result = await sendOtpApiStatus.run(
        apiClient("POST", "common", "/unauth/sendresendotp", {
          body: apiBody,
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      let {
        content: { data, otp, wait_time },
      } = result;

      //console.log("Result Forgot Password data: ", data);
      //console.log("Result Forgot Password otp): ", otp);
      //console.log("Result Forgot Password waitTime: ", wait_time);

      setResendTimerValue(wait_time);
      // setResendTimerValue((currentValue)=>currentValue+300000)
      timerActions.setTime(wait_time * 1000);
      timerActions.reset();
      timerActions.start();
    } catch (error) {
      //console.log("Forgot Password Component Error: ", error);

      notification.enqueueSnackbar(error?.userMessage, {
        variant: "error",
      });
    }
  };

  // let resendOtp = async (timerActions) => {
  //   let apiBody = {};

  //   if (state.from === "MFA") {
  //     apiBody = {
  //       user_id: state.user_id,
  //       entity_type: "admin",
  //       action_type: "login",
  //       device_id: DEVICE_ID,
  //       // socialData: socialData,
  //     };
  //   } else {
  //     apiBody = {
  //       email: state.email,
  //       entity_type: "admin",
  //       action_type: "forgot_password",
  //     };
  //   }

  //   console.log("email from resend otp api body", apiBody.email);

  //   try {
  //     const result = await sendOtpApiStatus.run(
  //       apiClient("POST", "common", "sendresendotp", {
  //         body: apiBody,
  //         shouldUseDefaultToken: true,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //     );

  //     let {
  //       content: { data, otp, wait_time },
  //     } = result;

  //     console.log("Result Forgot Password data: ", data);
  //     console.log("Result Forgot Password otp): ", otp);
  //     console.log("Result Forgot Password waitTime: ", wait_time);

  //     setResendTimerValue(wait_time);
  //     // setResendTimerValue((currentValue)=>currentValue+300000)
  //     timerActions.setTime(wait_time * 1000);
  //     timerActions.reset();
  //     timerActions.start();
  //   } catch (error) {
  //     console.log("Forgot Password Component Error: ", error);

  //     notification.enqueueSnackbar(error.message, {
  //       variant: "error",
  //     });
  //   }
  // };

  let validateOtp = async (apiBody) => {
    // let apiBody = {
    //   email: history.JSON.parse(window.localStorage.getItem('otpData')).email,
    //   otp: formik.values.otp,
    //   entity_type: "patient",
    //   action_type: "forgot_password",
    // };

    //console.log("Validate OTP APi Body: ", apiBody);

    try {
      const result = await ValidateOtpApiStatus.run(
        apiClient("POST", "common", "/unauth/validateotp", {
          body: apiBody,
          shouldUseDefaultToken: true,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      let {
        content: { data, otp },
      } = result;
      if (
        JSON.parse(window.localStorage.getItem("otpData")).from ===
        "delete-account"
      ) {
        navigate("/delete-success");
      } else {
        if (JSON.parse(window.localStorage.getItem("otpData")).from === "MFA") {
          //console.log("Result: ", data.is_complete_profile);
          auth.setUser(data);
          auth.setSubscription(
            data.is_subscription_active,
            data.stripe_customer_id
          );
          if (
            !_.isUndefined(data.is_complete_profile) &&
            data.is_complete_profile == false
          ) {
            navigate("/signup/profile", {
              data: {
                apiBody: JSON.parse(window.localStorage.getItem("otpData"))
                  .socialData,
              },
            });
          } else {
            if (
              window.localStorage.getItem("nonloggedInRedirection") !=
              undefined ||
              window.localStorage.getItem("nonloggedInRedirection") != null
            ) {
              navigate(
                window.localStorage.getItem("nonloggedInRedirection")
              );
            } else {
              navigate(
                JSON.parse(window.localStorage.getItem("otpData")).to
              );
            }
          }
        } else {
          if (data)
            window.localStorage.setItem(
              "reset",
              JSON.stringify({
                optVerified: data,
                ...apiBody,
                from: "otp-verification",
              })
            );
          navigate("/reset-password", {
            optVerified: data,
            ...apiBody,
            from: "otp-verification",
          });
        }
      }
    } catch (error) {
      //console.log("Forgot Password Component Error: ", error);

      notification.enqueueSnackbar(error?.userMessage, {
        variant: "error",
      });
    }
  };

  // let validateOtp = async (apiBody, menuData) => {
  //   console.log("Validate OTP APi Body: ", apiBody);

  //   try {
  //     const result = await ValidateOtpApiStatus.run(
  //       apiClient("POST", "common", "validateotp", {
  //         body: apiBody,
  //         shouldUseDefaultToken: true,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //     );

  //     let {
  //       content: { data, otp },
  //     } = result;

  //     if (state.from === "MFA") {
  //       auth.setUser(data);

  //       notification.enqueueSnackbar("Login Successfully", {
  //         variant: "success",
  //       });

  //       // history.replace('/');

  //       // -------------------------Menu-------------------------------------
  //       // let activePages = menuData.active_pages.map((page) => page.page);
  //       // var getActiveMenu = menu.filter((menu) =>
  //       //   activePages.includes(menu.textCompare)
  //       // )[0];
  //       // var links =
  //       //   getActiveMenu.child.length > 0
  //       //     ? getActiveMenu.child[0].url
  //       //     : getActiveMenu.url;
  //       // localStorage.setItem("rootPath", links);
  //       // localStorage.setItem(
  //       //   "admin_support_chat_id",
  //       //   data.admin_support_chat_id
  //       // );
  //       // window.location.href = links;
  //     } else {
  //       if (data)
  //         navigate("/reset-password", {
  //           state: {
  //             optVerified: data,
  //             ...apiBody,
  //             from: "otp-verification",
  //           },
  //         });
  //     }
  //   } catch (error) {
  //     console.log("Forgot Password Component Error: ", error);

  //     notification.enqueueSnackbar(error.message, {
  //       variant: "error",
  //     });
  //   }
  // };

  let { from } = state || { from: { pathname: "/" } };

  const loginApiStatus = useCallbackStatus();
  useEffect(() => {
    if (auth.getUserId()) {
      navigate("/");
    }
  });

  // useEffect(() => {
  //   if (
  //     _.isUndefined(history.location.state) ||
  //     history.location.state.from != "forgot-password"
  //   ) {
  //     console.log("History:", history);
  //     history.push("/forgot-password");
  //   }
  // });

  // const content = `Enter the 6-digit code Mental Health just sent to ${
  //   _.isUndefined(state) ? null : state.email
  // }`;

  return (
    <OuterLayout
      is_back_button={true}
      on_back={state && state.back_action ? state.back_action : "/forgot-password"}
    >
      <Box className={classes.mainHeading}>Verify your account</Box>

      <Box className={classes.subHeading}>
        Please enter the code received on your registered email address
      </Box>

      <Box className={classes.otpBox}>
        <OtpInput
          formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
          isInputNum={true}
          value={formik.values.otp}
          onChange={(otp) => {
            formik.setValues({ otp: otp });
          }}
          error={formik.touched.otp && formik.errors.otp}
          helperText={formik.touched.otp && formik.errors.otp}
          numInputs={6}
          // shouldAutoFocus
          className={classes.otpInput}
          // inputStyle={{
          //   fontSize: "29px",
          //   border: "none",
          //   borderBottom: "2px solid #825ffc",
          // }}
          inputStyle={{
            fontSize: "29px",
            border: "none",
            color: "rgba(0, 0, 0, 0.87)",
            outline: "none",
            backgroundColor: "#fff",
            borderBottom: "2px solid #825ffc",
            paddingLeft: "0",
            paddingRight: "0",
            borderRadius: "0",
            outline: "none",
          }}
        />
      </Box>

      <Box className={classes.buttons}>
        <Button
          onClick={formik.submitForm}
          className={classes.btn}
          disabled={ValidateOtpApiStatus.isPending}
          variant="contained"
          color="primary"
        >
          {ValidateOtpApiStatus.isPending ? (
            <CircularProgress size={20} />
          ) : (
            "VERIFY"
          )}
        </Button>
        <Timer
          formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
          initialTime={resendTimerValue}
          direction="backward"
        >
          {({ start, resume, pause, stop, reset, getTimerState, setTime }) => {
            const state = getTimerState();
            return (
              <>
                <Button
                  className={classes.btn}
                  style={{ color: "white", border: "1px solid #825ffc" }}
                  disabled={
                    state === "INITED" ||
                      state === "PLAYING" ||
                      sendOtpApiStatus.isPending
                      ? true
                      : false
                  }
                  onClick={() =>
                    resendOtp({ start, resume, pause, stop, reset, setTime })
                  }
                  variant="outlined"
                >
                  {state === "INITED" || state === "PLAYING" ? (
                    <>
                      {"Resend in "}
                      <React.Fragment>
                        <Timer.Minutes /> : <Timer.Seconds />
                      </React.Fragment>
                    </>
                  ) : (
                    <React.Fragment>
                      {state === "STOPPED" && sendOtpApiStatus.isPending ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Resend"
                      )}
                    </React.Fragment>
                  )}
                </Button>
              </>
            );
          }}
        </Timer>
      </Box>
    </OuterLayout>
  );
}
export default OtpVerification;
