import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Avatar } from "@mui/material";
import "./ViewUserProfile.css";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewUserProfile({ setShowUser, userProfileData }) {
  const [open, setOpen] = React.useState(true);

  const {
    country_code,
    profile_url,
    created_at,
    dob,
    email,
    first_name,
    last_name,
    phone_number,
    position,
    profession_name,
    rank_name,
    specialty_name,
    status,
    username,
    working_at,
  } = userProfileData;

  const userInformationData = [
    { key: "first_name", heading: "First Name" },
    { key: "last_name", heading: "Last Name" },
    { key: "dob", heading: "Date of Birth" },
    { key: "email", heading: "Email" },
    { key: "phone_number", heading: "Phone Number" },
    { key: "status", heading: "Status" },
    { key: "username", heading: "Username" },
  ];
  const professionalInformationData = [
    { key: "profession_name", heading: "Profession" },
    { key: "specialty_name", heading: "Specialty" },
    { key: "sub_specialties", heading: "Sub-Specialties" },
    { key: "rank_name", heading: "Rank" },
    { key: "position", heading: "Position" },
    { key: "working_at", heading: "Working At" },
  ];

  const handleClose = () => {
    setOpen(false);
    setShowUser(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#141522" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {first_name}'s Profile
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="DetailedProfileParent" style={{ padding: "8vh 5vw" }}>
          <div className="UserAvatarDetails WhiteCardBox flex-aicjcsb">
            <div className="AvatarBox flex-aic flexgap1">
              <div className="AvatarImg">
                <Avatar
                  sx={{ height: "82px", width: "82px" }}
                  alt={first_name}
                  src={profile_url}
                />
              </div>
              <div className="UserNameDetails">
                <Typography
                  variant="h1"
                  style={{ marginBottom: ".5rem", fontSize: "1.2rem" }}
                >
                  {first_name + " " + last_name}
                </Typography>
                <Typography variant="h3">{email}</Typography>
              </div>
            </div>

            <div className="RegisteredDate">
              <Typography variant="h2">Registered Date</Typography>
              <Typography variant="subtitle2">
                {moment.unix(created_at).format("Do MMM YYYY") || "-"}
              </Typography>
            </div>
          </div>
          <br />

          <div className="WhiteCardBox">
            <div>
              <Typography
                variant="h1"
                style={{ marginBottom: "2rem", fontSize: "1.2rem" }}
              >
                User Information
              </Typography>

              <div className="ProfessionalDetails">
                {userInformationData.map((tabDetail) => (
                  <div key={tabDetail.key} className="DetailsTabs">
                    <div className="TabHeading">
                      <Typography variant="h2">{tabDetail.heading}</Typography>
                    </div>
                    <div className="TabValues">
                      {/* Concatenate country_code and phone_number with a space in between */}
                      <Typography variant="subtitle2">
                        {tabDetail.key === "phone_number"
                          ? `${userProfileData["country_code"]} ${userProfileData["phone_number"]}`
                          : userProfileData[tabDetail.key] || "-"}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>

              <br />
              <br />
              <Typography
                variant="h1"
                style={{ marginBottom: "2rem", fontSize: "1.2rem" }}
              >
                Professional Information
              </Typography>

              <div className="ProfessionalDetails">
                {professionalInformationData.map((tabDetail) => (
                  <div key={tabDetail.key} className="DetailsTabs">
                    <div className="TabHeading">
                      <Typography variant="h2">{tabDetail.heading}</Typography>
                    </div>
                    <div className="TabValues">
                      <Typography variant="subtitle2">
                        {tabDetail.key === "sub_specialties"
                          ? userProfileData["sub_specialties"]
                              ?.map((ele) => ele.sub_specialty_name)
                              .join(", ")
                          : userProfileData[tabDetail.key] || "-"}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

// =country_code: "+91"
// =created_at: null
// =dob: "03/02/1993"
// =email: "sahil123@yopmail.com"
// =first_name: "Sahil"
// =last_name: "Dadhoriya"
// =phone_number: "8294368111"
// =position: null
// =profession_name: null
// =rank_name: null
// =specialty_name: null
// =status: "approved"
// =username: "sahil009"
// =working_at: null
